
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { BaseComponent } from '../../../shared/base.component';
import { Space } from '../../../services/data/spaces/space.class';

import * as dayjs from 'dayjs';
import { BookingData } from '../../../overlays/booking-modal/booking-modal.component';
import { HashMap } from '../../../shared/utilities/types.utilities';
import { ApplicationService } from '../../../services/app.service';

export interface IPanelTimelineOptions {
    /** Length of time in minutes to display on the timeline after the current time */
    duration: number;
    /** Time offset in minutes which is displayed before the current time marker */
    start_offset: number;
    /** Size of shown blocks in minutes */
    block_size: number;
}

interface ITimelineBlock {
    /** Block time as ms since UTC epoch */
    value: number;
    /** Whether to show block divider */
    show: boolean;
    /** Hour of the day represented by this time block */
    hour: number;
    /** Whether block is in use or not */
    in_use: boolean;
}

@Component({
    selector: 'panel-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class PanelTimelineComponent extends BaseComponent implements OnInit, OnChanges {
    /** Space with which to display bookings for */
    @Input() public space: Space;
    /** Display options for the timeline */
    @Input() public options: IPanelTimelineOptions;
    /** Emitter for user events on the timeline */
    @Output() public event = new EventEmitter();

    /** List of display time block  */
    public time_blocks: ITimelineBlock[] = [];
    /** Whether to hide unavailable blocks on the UI */
    public hide_status: boolean;
    /** Whether to hide all booking data */
    public hide_all: boolean;
    /** Whether user is not allowed to make bookings */
    public no_booking: boolean;
    /** Whether user is not allowed to make bookings using the timeline */
    public no_timeline_bookings: boolean;
    /** Default title for bookings */
    public default_title: string;
    /** Maximum allowed booking duation */
    public max_duration: number;
    /** Minimum allowed booking duation */
    public min_duration: number;

    /** Display string for the current time */
    public get display_time(): string {
        return dayjs().format('h:mm A')
    }

    constructor(private service: ApplicationService) {
        super();
    }

    public ngOnInit(): void {
        this.generateTimelineBlocks();
        this.interval('update_blocks', () => this.generateTimelineBlocks(), 60 * 1000);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.generateTimelineBlocks();
    }

    /**
     * Generate time blocks to display
     */
    private generateTimelineBlocks(): void {
        let now = dayjs().startOf('m');
        now = now.minute(Math.round(now.minute() / 5) * 5);
        const start = now.subtract((this.options ? this.options.start_offset : null) || 120, 'm');
        const end = start.add((this.options ? this.options.duration : null) || 720, 'm');
        let date = dayjs(start);
        delete this.time_blocks;
        this.time_blocks = [];
        while (date.isBefore(end, 'm')) {
            this.time_blocks.push({
                value: date.valueOf(),
                show: (date.minute() % ((this.options ? this.options.block_size : null) || 30)) === 0,
                hour: date.minute() === 0 ? (date.hour() % 12 === 0 ? 12 : date.hour() % 12) : null,
                in_use: false
            });
            date = date.add(5, 'm');
        }
        this.checkBookings();
    }

    /**
     * Update the statuses of time blocks based off space bookings
     */
    public checkBookings(): void {
        if (this.space && this.space.todays_bookings) {
            const block_start = dayjs().subtract((this.options ? this.options.start_offset : null) || 120, 'm');
            const block_end = dayjs().add((this.options ? this.options.duration : null) || 720, 'm');
            // Filter bookings for the shown times
            const bookings = this.space.bookings.filter(i => {
                const start = dayjs(i.date);
                const end = start.add(i.duration, 'm');
                return end.isAfter(block_start, 'm') && start.isBefore(block_end, 'm');
            });
            this.time_blocks.forEach(i => i.in_use = false);
            if (!this.hide_status && !this.hide_all) {
                for (const booking of bookings) {
                    const start = dayjs(booking.date);
                    const end = start.add(booking.duration, 'm');
                    for (const block of this.time_blocks) {
                        const block_time = dayjs(block.value);
                        if (block_time.isSame(start, 'm') || (block_time.isAfter(start, 'm') && block_time.isBefore(end, 'm'))) {
                            block.in_use = true;
                        }
                    }
                }
            }
        }
    }

    /**
     * Open modal to make a new booking
     */
    public book(block: ITimelineBlock) {
        this.service.Bookings.new({
            space: this.space,
            date: block.value,
            title: this.default_title,
            max_duration: this.max_duration,
            min_duration: this.min_duration
        });
    }
}

