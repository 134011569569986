import { Component } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../base.component';
import { getNextFreeBookingSlot } from '../../../services/data/bookings/booking.utilities';
import { humaniseDuration } from '../../utilities/general.utilities';
import { FORM_FIELDS } from '../../globals/form-fields';

import * as dayjs from 'dayjs';

@Component({
    selector: 'custom-duration-field',
    templateUrl: './duration-field.component.html',
    styleUrls: ['./duration-field.component.scss']
})
export class CustomDurationFieldComponent extends BaseComponent  {
    /** Form Control holding date data */
    public date_control: AbstractControl;
    /** Form Control holding space data */
    public space_control: AbstractControl;
    /** Step size for available times */
    public block_size = 15;
    /** Step size for available times */
    public active_duration: { id: number, name: string };
    /** List of available durations */
    public options: { id: number, name: string }[] = [];

    constructor(protected field: ADynamicFormField, public group: FormGroup) {
        super();
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.date) {
                this.date_control = this.group.controls.date;
                this.subscription('date', this.date_control.valueChanges.subscribe(() => this.calculateAvailableDurations()));
            }
            if (this.group.controls.room || this.group.controls.space) {
                this.space_control = this.group.controls.room || this.group.controls.space;
                this.subscription('space', this.space_control.valueChanges.subscribe(() => this.calculateAvailableDurations()));
            }
            this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        }
        this.calculateAvailableDurations();
    }

    /**
     * Update the active value displayed on the dropdown
     */
    public updateDisplay() {
        if (this.options && this.options.length > 0) {
            if (!this.active_duration) {
                if (this.field.getValue()) {
                    this.active_duration = this.options.find(i => i.id === this.field.getValue());
                }
                if (!this.active_duration) {
                    this.active_duration = this.options[0];
                    this.setValue(this.options[0]);
                }
            } else if (!this.options.find(i => i.id === this.active_duration.id)) {
                let diff = 9999;
                let new_opt = null;
                for (const option of this.options) {
                    if (Math.abs(option.id - this.active_duration.id) < diff) {
                        diff = Math.abs(option.id - this.active_duration.id);
                        new_opt = option;
                    }
                }
                if (new_opt) {
                    this.active_duration = new_opt;
                    this.setValue(new_opt);
                }
            }
        }
    }

    /**
     * Generate a list of times selectable by the user
     */
    public calculateAvailableDurations() {
        this.options = [];
        let date = dayjs().startOf('m');
        const max_duration = this.field.metadata ? this.field.metadata.max_duration || 600 : 600;
        const min_duration = this.field.metadata ? this.field.metadata.min_duration || 30 : 30;
        let end = date.add(max_duration, 'm');
        if (this.space_control) {
            // Get times based off available times in space
            if (this.date_control && this.date_control.value) {
                date = dayjs(this.date_control.value).startOf('m');
            }
            const slot = getNextFreeBookingSlot(this.space_control.value.bookings, date.valueOf(), min_duration);
            if (slot) {
                // Check if slot is after the active date
                date = slot.start > date.valueOf() ? dayjs(slot.start).startOf('m') : date;
            }
            end = slot ? dayjs(slot.end).startOf('m') : dayjs(date).add(max_duration, 'm');
        }
        end = end.endOf('m');
        // Get available durations between the date and end times
        date = date.add(min_duration, 'm').startOf('m');
        let duration = min_duration;
        for (; date.isBefore(end, 's'); date = date.add(this.block_size, 'm')) {
            this.options.push({ id: duration, name: humaniseDuration(duration) });
            duration += 15;
            if (this.options.length > Math.floor((max_duration - min_duration) / this.block_size)) {
                break;
            }
        }
        this.updateDisplay();
    }

    /**
     * Update the value of the form field
     * @param value New value
     */
    public setValue(block: { id: number, name: string }) {
        this.field.setValue(block.id);
    }
}

FORM_FIELDS.duration = CustomDurationFieldComponent;
