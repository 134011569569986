import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from '../shared/base.component';
import { ApplicationService } from '../services/app.service';
import { version, build } from '../shared/globals/application';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-shell-wrapper',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellWrapperComponent extends BaseComponent implements OnInit {
    /** Whether application has system */
    public has_system: boolean;
    /** ID of the active system */
    public system_id: string;
    /** Name of the active system */
    public system_name: string;
    /** Name of the active page */
    public page: string;
    /** List of informational messages to display while connecting to the active system */
    public info_messages: string[] = [
        'If this message persists, check you network connection and then try reloading this page.',
        'It seems to be taking a while. Make sure the selected system is valid.',
        'Still nothing, huh. Are you authenticated? Do you have have a network connection?',
        'Try reloading the page. If this persists contact your administrator.'
    ];
    /** Index of the info message being displayed */
    public message_index: number = 0;

    /** Current version of the application */
    public get version(): string {
        return version;
    }

    /** Current version of the application */
    public get build(): string {
        const now = dayjs();
        const built = build.format('DD/MM/YY HH:mm');
        return built;
    }

    public get show_version(): boolean {
        return this._service.setting('app.show_version');
    }

    constructor(private _service: ApplicationService, private _router: Router) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('app_ready', this._service.initialised.subscribe((is_ready) => {
            if (is_ready) {
                // Listen for system changes
                this.subscription('system', this._service.listen('system', (value) => this.system_id = value));
                // Listen for routing events
                this.page = this._router.url.split('/')[1] || '';
                this.subscription(
                    'router.change',
                    this._router.events.subscribe(event => {
                        if (event instanceof NavigationEnd) {
                            this.page = this._router.url.split('/')[1] || '';
                        }
                    })
                );
                this.unsub('app_ready');
            }
        }));
    }

    /**
     * Check if the system data has loaded
     * @param delay Delay before first loading message update
     */
    public checkLoading(delay: number = 3000) {
        if (!this.system_name) {
            this.message_index = 0;
            this.timeout('update_msg', () => this.updateMessage(), delay);
        }
    }

    /**
     * Update the index of the info message displayed
     */
    private updateMessage(): void {
        if (!this.system_id) { return; }
        this.message_index++;
        if (this.message_index === this.info_messages.length) {
            this.message_index--;
            return;
        }
        this.timeout('update_msg', () => this.updateMessage(), 3000);
    }

    /**
     * Schedule a reload the UI for a future time
     * @param time Time to reload the UI
     */
    public reloadAt(time: number): void {
        if (time && typeof time === 'number') {
            this.clearTimeout('reload_ui');
            const now = dayjs().startOf('s');
            const reload_at_time = dayjs((time || 0) * 1000).startOf('s');
            const diff = reload_at_time.diff(now, 'ms');
            if (diff > 100) {
                this.timeout('reload_ui', () => location.reload(true), diff);
            }
        }
    }
}
