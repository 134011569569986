<div class="space-booking-details">
    <div class="details current" *ngIf="space && space.current">
        <div class="time" *ngIf="!hide_availability">Booked until {{ space.current?.end_time }}</div>
        <div class="title" *ngIf="!hide_title && !hide_details">{{ space.current?.title }}</div>
        <div class="host" *ngIf="!hide_user && !hide_details">{{ space.current?.organiser?.name }}</div>
    </div>
    <div class="details next" *ngIf="space && space.next">
        <div class="time" *ngIf="!hide_availability">Next meeting at {{ space.next?.start_time }}</div>
        <div class="title" *ngIf="!hide_title && !hide_details">{{ space.next?.title }}</div>
        <div class="host" *ngIf="!hide_user && !hide_details">{{ space.next?.organiser?.name }}</div>
    </div>
    <div class="details other" *ngIf="space && space.current && space.next">
        <div class="time" *ngIf="!hide_availability">
            Free {{ next_free.start ? 'at ' + next_free.start : '' }} {{ next_free.end ? 'until ' + next_free.end : '' }}
        </div>
    </div>
    <div class="information" *ngIf="title || description">
        <div class="title">{{ title }}</div>
        <div class="block">
            <div class="icon" *ngIf="icon">
                <img [src]="icon">
            </div>
            <div class="text">{{ description }}</div>
        </div>
    </div>
    <div class="bindings" *ngIf="space">
        <i binding [sys]="space.id" mod="Bookings" bind="hide_details" [(model)]="hide_details"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="hide_title" [(model)]="hide_title"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="hide_availability" [(model)]="hide_availability"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="hide_user" [(model)]="hide_user"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="title" [(model)]="title"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="description" [(model)]="description"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="icon" [(model)]="icon"></i>
    </div>
</div>
