
import { Injectable } from '@angular/core';
import { ComposerService } from '@acaprojects/ngx-composer';

import { BaseAPIService } from '../base.service';
import { Space, ISpaceAvailabilityOptions } from './space.class';
import { HashMap } from '../../../shared/utilities/types.utilities';

import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class SpacesService extends BaseAPIService<Space> {

    constructor(protected _composer: ComposerService) {
        super(_composer);
        this._name = 'Space';
        this._api_route = 'rooms';
        this._compare = (a, b) => !a.id.localeCompare(b.id) || !a.email.localeCompare(b.email);
        this._list_filter = (a: Space) => {
            const bld = this.parent.Organisation.building;
            return a.level.bld_id === bld.id;
        };
    }

    /**
     * Get available spaces
     * @param options
     */
    public available(options: ISpaceAvailabilityOptions): Promise<Space[]> {
        if (!options) { throw new Error('Space avilability requires request options'); }
        if (!options.date) { options.date = dayjs().startOf('m').valueOf(); }
        const key = `available|${options.id ? options.id : ''}`;
        if (!this._promises[key]) {
            this._promises[key] = new Promise((resolve, reject) => {
                const respond = (list: Space[]) => {
                    const available_spaces = list.filter(i => {
                        const linked = list.filter(j => i.linked_spaces.indexOf(j.id) >= 0);
                        return i.was_available && linked.reduce((a, v) => a && v.was_available, true);
                    });
                    resolve(available_spaces);
                };
                const error = (e) => reject(e);
                if (options.id) {
                    this.show(options.id, options).then((i) => respond([i]), error);
                } else {
                    this.query(options).then(respond, error);
                }
            });
        }
        return this._promises[key];
    }

    /**
     * Convert raw data into API object
     * @param raw_data Raw API data
     */
    protected process(raw_data: HashMap): Space {
        return new Space(this, raw_data);
    }
}
