
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EngineSystem } from '@acaprojects/ts-composer';

import { ApplicationService } from '../../services/app.service';
import { BaseComponent } from '../../shared/base.component';

@Component({
    selector: 'app-bootstrap',
    templateUrl: './bootstrap.component.html',
    styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent extends BaseComponent implements OnInit {
    /** Whether to show manual input for bootstrap ID */
    public manual_input: boolean;
    /** List of available systems */
    public system_list: EngineSystem[] = [];
    /** Whether application data is loading */
    public loading: boolean;
    /** ID of the system to bootstrap */
    public system_id: string;
    /** Selected system to bootstrap */
    public selected_system: EngineSystem = null;
    /** Whether input field is focused */
    public input_focus: boolean;

    constructor(private service: ApplicationService, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.loading = true;
        this.subscription('app_ready', this.service.initialised.subscribe((is_ready) => {
            if (is_ready) {
                this.subscription('route.query', this.route.queryParamMap.subscribe((params) => {
                    if (params.has('clear') && params.get('clear')) {
                        this.clearBootstrap();
                    }
                    if (params.has('system_id') || params.has('sys_id')) {
                        this.system_id = params.get('system_id') || params.get('sys_id');
                        this.manual_input = true;
                        this.bootstrap();
                    }
                }));
                this.subscription('system_list', this.service.Systems.listen('list', (systems) => {
                    this.system_list = systems || [];
                    this.manual_input = !this.system_list || this.system_list.length <= 0;
                }));
                this.loadList();
                this.checkBootstrapped();
                this.unsub('app_ready');
            }
        }));
    }

    /**
     * Setup the default system for the application to bind to
     */
    public bootstrap() {
        if (this.manual_input && this.system_id) {
            this.configure(this.system_id);
        } else if (this.selected_system) {
            this.configure(this.selected_system.id);
        }
    }

    /**
     * Grab the list of systems from the Systems Manager
     * @param tries Number of previous attempts
     */
    private loadList(tries: number = 0): void {
        this.system_list = this.service.Systems.list || [];
        if ((!this.system_list || this.system_list.length <= 0) && tries < 10) {
            this.timeout('load', () => this.loadList(++tries));
            return;
        }
        if (!this.system_list || this.system_list.length <= 0) {
            this.manual_input = true;
        }
        this.loading = false;
    }

    /**
     * Check if the application has previously been bootstrapped
     */
    private checkBootstrapped(): void {
        this.loading = true;
        if (localStorage) {
            const system_id = localStorage.getItem('ACA.PANEL.system');
            if (system_id) {
                this.service.navigate(['panel', system_id]);
                return;
            }
        }
        this.loading = false;
    }

    /**
     * Save the bootstrapped ID and redirect to the panel for that ID
     * @param system_id System to bootstrap
     */
    private configure(system_id: string): void {
        if (localStorage) {
            localStorage.setItem('ACA.PANEL.system', system_id);
        }
        this.service.navigate(['panel', system_id]);
    }

    /**
     * Remove any previously set bootstrapping details
     */
    private clearBootstrap() {
        if (localStorage) {
            localStorage.removeItem('ACA.PANEL.system');
        }
    }

}

