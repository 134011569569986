import { padZero } from '../../../shared/utilities/general.utilities';

import { generateMockUser } from '../users/user.utilities';

import * as faker from 'faker';
import * as dayjs from 'dayjs';
import { Booking } from './booking.class';

let BOOKING_COUNT = 0;
let BOOKING_DATE = dayjs().minute(0).startOf('m');

/**
 * Set the initial time used for generating mock bookings
 * @param time New initial time as ms from UTC epoch
 */
export function setMockBookingStartDatetime(time: number) {
    BOOKING_DATE = dayjs(time).startOf('h');
}

/**
 * Create mock raw API data for a booking
 * @param id
 */
export function generateMockBooking(id?: string) {
    if (!id) {
        id = `booking-${BOOKING_COUNT++}`;
    }
    BOOKING_DATE = BOOKING_DATE.add(Math.floor(Math.random() * 4) + 15, 'm');
    const start = BOOKING_DATE.valueOf();
    const duration = Math.floor(Math.random() * 4) + 30;
    BOOKING_DATE = BOOKING_DATE.add(Math.floor(Math.random() * 4) + 15, 'm');
    return {
        id,
        icaluid: padZero(Math.floor(Math.random() * 99999999), 8),
        title: `${faker.commerce.productName()} Meeting`,
        attendees: Array(Math.floor(Math.random() * 5 + 2))
            .fill(0)
            .map((i) => generateMockUser()),
        organiser: generateMockUser(),
        date: start,
        duration,
        description: faker.lorem.paragraph(),
        notes: faker.lorem.paragraph(),
        location: faker.address.city(),
        catering: Math.floor(Math.random() * 34567) % 3 === 0,
    };
}

export interface IBookingSlot {
    start: number;
    end: number;
}

/**
 * Generate a list of free time slots between the given bookings
 * @param list List of bookings to find slots between
 * @param min_size Minimum length of a free slot in minutes
 */
export function getFreeBookingSlots(list: Booking[], min_size: number = 30): IBookingSlot[] {
    /* istanbul ignore else */
    if (!list?.length) {
        return [
            {
                start: 0,
                end: dayjs().startOf('m').valueOf() * 10,
            },
        ];
    }
    const slots: IBookingSlot[] = [];
    let start = 0;
    list.sort((a, b) => a.date - b.date);
    for (const booking of list) {
        if (start < booking.date) {
            slots.push({ start, end: booking.date });
        }
        start = booking.date + booking.duration * 60 * 1000;
    }
    slots.push({ start, end: dayjs(start).add(10, 'y').endOf('d').valueOf() });
    return slots.filter((slot) => Math.abs(slot.end - slot.start) > min_size * 60 * 1000);
}

/**
 * Get the next free time slot from the given bookings
 * @param list List of bookings to find the next slot
 * @param date Date to find next slot after in ms since UTC epoch
 * @param min_size Minimum length of the free slot in minutes
 */
export function getNextFreeBookingSlot(
    list: Booking[],
    date: number = dayjs().valueOf(),
    min_size: number = 30
): IBookingSlot {
    const slots = getFreeBookingSlots(list, min_size);
    const time = dayjs(date).startOf('m').second(1);
    for (const block of slots) {
        const start = dayjs(block.start).startOf('m');
        const end = dayjs(block.end).startOf('m');
        if (start.isAfter(time, 's')) {
            return block;
        } else if (time.isBefore(end, 's')) {
            const duration = end.diff(time, 'm');
            /* istanbul ignore else */
            if (duration >= min_size) {
                return block;
            }
        }
    }
    return slots[slots.length - 1];
}

export function timePeriodsIntersect(start1: number, end1: number, start2: number, end2: number, type = '') {
    return (
        (start1 >= start2 && start1 < end2) ||
        (end1 > start2 && end1 <= end2) ||
        (start2 >= start1 && start2 < end1) ||
        (end2 > start1 && end2 <= end1)
    );
}
