
import { formatDate } from '@angular/common';
import { Validators } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseDataClass } from '../base-api.class';
import { User } from '../users/user.class';
import { HashMap } from '../../../shared/utilities/types.utilities';
import { humaniseDuration } from '../../../shared/utilities/general.utilities';
import { Space } from '../spaces/space.class';
import { FORM_FIELDS } from '../../../shared/globals/form-fields';

import { formatAttendeesWithHost, formatRecurrence, formatSpaces } from '../../../shared/utilities/formatting.utilities';
import { validateDate, buildValidateDuration, buildValidateAttendees } from '../../../shared/utilities/validation.utilities';

import * as dayjs from 'dayjs';
import { BaseAPIService } from '../base.service';

export class Booking extends BaseDataClass {
    /** Unique calendar event ID */
    readonly icaluid: string;
    /** Subject or title of the booking */
    readonly title: string;
    /** Unix timestamp of the booking start */
    readonly date: number;
    /** Length of the booking in minutes */
    readonly duration: number;
    /** Description or details of the booking */
    readonly body: string;
    /** Supplimentary notes for the booking */
    readonly notes: string;
    /** Type of booking */
    readonly type: string;
    /** Whether the booking has catering associated with it */
    readonly catering: boolean;
    /** String location set in booking */
    readonly _location: string;
    /** List of attendee emails associated with the booking */
    private _attendees: string[];
    /** Email of the host or organiser of the booking */
    private _organiser: string;
    /** List of spaces associated with the booking */
    private _space_list: string[];
    /** Cached display strings */
    private _display: HashMap<string> = {};

    constructor(protected service: BaseAPIService<Booking>, raw_data: HashMap) {
        super(service, raw_data);
        const u_service = service.parent.Users;
        this.icaluid = raw_data.icaluid || this.id;
        this.title = raw_data.title || raw_data.subject || raw_data.Subject;
        const start = dayjs(raw_data.date || raw_data.start || raw_data.start_epoch * 1000 || raw_data.Start);
        this.date = start.valueOf();
        this.duration = raw_data.duration || dayjs(raw_data.end || raw_data.end_epoch * 1000 || raw_data.End).diff(start, 'm');
        this.body = raw_data.body || raw_data.description;
        this.notes = raw_data.notes;
        this.type = raw_data.type || (raw_data.visitors ? 'external' : null) || 'internal';
        this.catering = raw_data.catering;
        this._attendees = (raw_data.attendees || raw_data._attendees || []).map(i => u_service.item(i.email) || new User(u_service, i));
        const organiser = raw_data.organiser || raw_data.organizer || { email: `${raw_data.owner}@fake.org` };
        if (raw_data.owner) {
            this.service.parent.Users.addFrom('booking', { name: raw_data.owner, email: `${raw_data.owner}@fake.org` });
        }
        this._organiser = organiser.email || raw_data._organiser;
        this._location = raw_data.location || raw_data._location;
        this._display = {
            date: start.format('DD MMM YYYY'),
            time: `${start.format('h:mma')} - ${dayjs(start)
                .add(this.duration, 'm')
                .format('h:mma')}`,
            start: start.format('h:mma'),
            end: dayjs(start)
                .add(this.duration, 'm')
                .format('h:mma'),
            duration: humaniseDuration(this.duration)
        };
        this._space_list = raw_data.room_ids || raw_data._space_list;
        // Add attendees and organiser to service data
        if (this.service.parent && this.service.parent.Users) {
            if (raw_data.attendees) {
                raw_data.attendees.forEach(u => {
                    if (!this.service.parent.Users.item(u.email)) {
                        this.service.parent.Users.addFrom(this.id, u, 'class');
                    }
                });
            }
            if (organiser) {
                if (!this.service.parent.Users.item(organiser.email)) {
                    this.service.parent.Users.addFrom(this.id, organiser, 'class');
                }
            }
        }
    }

    /** List of attendees to the meeting */
    public get attendees(): User[] {
        return this.service && this.service.parent && this.service.parent.Users
            ? this.service.parent.Users.list((user) => this._attendees.indexOf(user.id) >= 0)
            : [];
    }

    /** Host or organiser of the meeting */
    public get organiser(): User {
        return this.service && this.service.parent && this.service.parent.Users && this._organiser
            ? this.service.parent.Users.item(this._organiser) : null;
    }

    /** Status of the booking */
    public get status(): 'future' | 'upcoming' | 'done' | 'started' | 'in_progress' {
        const now = dayjs();
        const date = dayjs(this.date);
        if (now.isBefore(date.subtract(15, 'm'), 'm')) {
            return 'future';
        } else if (now.isBefore(date, 'm')) {
            return 'upcoming';
        } else if (now.isBefore(date.add(15, 'm'), 'm')) {
            return 'started';
        } else if (now.isBefore(date.add(this.duration, 'm'), 'm')) {
            return 'in_progress';
        }
        return 'done';
    }

    /** Get the first space from the space list */
    public get space(): Space {
        return this.service && this.service.parent && this.service.parent.Spaces && this._space_list && this._space_list.length > 0
            ? this.service.parent.Spaces.item(this._space_list[0]) : null;
    }
    /** Spaces associated with the booking */
    public get space_list(): Space[] {
        return this.service && this.service.parent && this.service.parent.Spaces
            ? this.service.parent.Spaces.list((space) => this._space_list.indexOf(space.id) >= 0)
            : [];
    }

    /** Display value for the date */
    public get date_string(): string {
        return this._display.date;
    }
    /** Display valuie for the start and end times of the booking */
    public get time_period(): string {
        return this._display.time;
    }
    /** Display value for the start time of the booking */
    public get start_time(): string {
        return this._display.start;
    }
    /** Display value for the end time of the booking */
    public get end_time(): string {
        return this._display.end;
    }
    /** Display value for the duration of the booking */
    public get length_string(): string {
        return this._display.duration;
    }
    /** Display value for the location of the booking */
    public get location(): string {
        return this._display.space_names || this._location || 'No location set';
    }
    /** Display value for the level of the first space in the booking */
    public get level(): string {
        return this._display.level;
    }

    /**
     * Create form fields for the object
     */
    protected initialiseFormFields(): ADynamicFormField[] {
        const form_fields = [];
        const form_settings = this.service.setting('fields') || [];
        const user = this.service.parent.Users.current;
        const actions = {
            // space: () => this.setSpace(),
            // recurrence: () => this.setRecurrence(),
            // conferencing: (v?) => this.setConferencing(v)
        };
        const validators = {
            date: [validateDate],
            duration: [buildValidateDuration()],
            attendees: [buildValidateAttendees(user, this.service.setting('min_attendees'))],
            terms: [Validators.requiredTrue]
        };
        const formatters = {
            attendees: formatAttendeesWithHost(user),
            date: formatDate,
            space: formatSpaces,
            recurrence: formatRecurrence
        };
        const custom_elements = FORM_FIELDS;
        const defaults = {};
        const process_field = field => {
            const f = {
                key: field.key,
                label: field.label,
                icon: field.icon,
                type: field.control_type || field.type,
                value: this[field.key] || defaults[field.key] || '',
                action: field.action || actions[field.key],
                format: field.format || formatters[field.key],
                content: field.content || custom_elements[field.key],
                required: field.required,
                validators: validators[field.key],
                references: field.references || field.refs,
                hide: field.hide,
                metadata: { ...field.metadata, ...this._form_metadata, service: this.service },
                attributes: { flex: field.flex },
                children: field.children ? field.children.map(i => process_field(i)) : null
            };
            return new ADynamicFormField(f);
        };
        for (const field of form_settings) {
            if (field.key) {
                form_fields.push(process_field(field));
            }
        }
        return form_fields;
    }

    /**
     * Make a copy of this object
     */
    public clone(): Booking {
        return new Booking(this.service, this);
    }

    /**
     * Make a copy of this object without identification data
     */
    public duplicate(): Booking {
        return new Booking(this.service, { ...this, id: null, email: null, icaluid: null });
    }
}
