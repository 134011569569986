
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedContentModule } from '../shared/shared.module';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { EmbeddedControlModalComponent } from './embedded-control-modal/embedded-control-modal.component';
import { BookingModalComponent } from './booking-modal/booking-modal.component';

const OVERLAYS: Type<any>[] = [
    ConfirmModalComponent,
    EmbeddedControlModalComponent,
    BookingModalComponent
];

@NgModule({
    declarations: [
        ...OVERLAYS
    ],
    imports: [
        CommonModule,
        SharedContentModule
    ],
    exports: [
        ...OVERLAYS
    ],
    entryComponents: [
        ...OVERLAYS
    ]
})
export class SharedOverlaysModule {}
