import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShellWrapperComponent } from './shell/shell.component';
import { BootstrapComponent } from './shell/bootstrap/bootstrap.component';
import { BookingPanelComponent } from './shell/panel/panel.component';
import { BookingPanelArrayComponent } from './shell/panel-array/panel-array.component';
import { BookingPanelSelectComponent } from './shell/panel-select/panel-select.component';

const routes: Routes = [
    {
        path: '',
        component: ShellWrapperComponent,
        children: [
            { path: 'bootstrap', component: BootstrapComponent },
            { path: 'panel/:system_id', component: BookingPanelComponent },
            { path: 'panel-array', component: BookingPanelArrayComponent },
            { path: 'panel-select', component: BookingPanelSelectComponent },
            { path: '**', redirectTo: 'bootstrap' }
        ]
    },
    { path: '**', redirectTo: 'bootstrap' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
