import { BaseDataClass } from '../base-api.class';
import { HashMap, Identity } from '../../../shared/utilities/types.utilities';
import { getItemWithKeys } from '../../../shared/utilities/general.utilities';
import { BaseAPIService } from '../base.service';
import { Organisation } from './organisation.class';

export interface IBuildingRoleUser {
    name: string;
    email: string;
    phone: string;
}

export interface LockerMap {
    [zone: string]: {
        [area: string]: {
            [type: string]: (string | boolean)[][];
        };
    };
}

export interface ICoordinates {
    longitude: number;
    latitude: number;
}

export interface IMapFeature {
    id: string;
    level_id: string;
    name: string;
}

export class Building extends BaseDataClass {
    /** Engine Zone ID for the building */
    readonly zone_id: string;
    /** List of available extras for the building */
    private _extras: Identity[];
    /** List of available extra equipment for loan at the building */
    private _loan_items: Identity[];
    /** List of available levels for the building */
    private _levels: Identity[];
    /** Map of custom settings for the building */
    private _settings: HashMap;
    /** Map of roles and list of the associated users */
    private _roles: HashMap<IBuildingRoleUser[]>;
    /** Map of the locker ID arrays */
    private _lockers: LockerMap;
    /** Map of important system ids for the building */
    private _systems: HashMap<string>;
    /** Map of important phone numbers for the building */
    private _phone_numbers: HashMap<string>;
    /** Globe coordiates for the build */
    private _location: ICoordinates;
    /** List of zones associated with the building */
    private _zones: string[];
    /** Searchable map features */
    private _searchables: IMapFeature[];

    constructor(protected service: BaseAPIService<Organisation>, raw_data: HashMap) {
        super(service, raw_data);
        this.zone_id = raw_data.zone_id || raw_data.zone;
        this._extras = (raw_data.extras || []).map(i => ({ id: i.extra_id || i.id, name: i.extra_name || i.name }));
        this._loan_items = (raw_data.loan_items || []).map(i => ({ id: i.extra_id || i.id, name: i.extra_name || i.name }));
        this._levels = (raw_data.levels || []).map(i => ({ id: i.level_id || i.id, name: i.level_name || i.name }));
        this._roles = raw_data.roles || {};
        this._lockers = raw_data.lockers || raw_data.locker_structure || {};
        this._systems = raw_data.systems || {};
        this._settings = raw_data.settings || {};
        this._phone_numbers = raw_data.phone_numbers || {};
        this._location = raw_data.location || { longitude: null, latitude: null };
        this._searchables = [];
        if (raw_data.neighbourhoods) {
            for (const lvl in raw_data.neighbourhoods) {
                if (raw_data.neighbourhoods.hasOwnProperty(lvl)) {
                    const lvl_features = raw_data.neighbourhoods[lvl] || {};
                    for (const feature in lvl_features) {
                        if (lvl_features.hasOwnProperty(feature)) {
                            this._searchables.push({
                                id: lvl_features[feature],
                                name: feature,
                                level_id: lvl
                            });
                        }
                    }
                }
            }
        }
    }

    /** List of available extras for the building */
    public get extras(): Identity[] {
        return [...(this._extras || [])];
    }
    /** List of available extras for the building */
    public get loan_items(): Identity[] {
        return [...(this._loan_items || [])];
    }
    /** List of available levels for the building */
    public get levels(): Identity[] {
        return [...(this._levels || [])];
    }
    /**
     * Get a custom building setting
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        const keys = key.split('.');
        const value = getItemWithKeys(keys, this._settings);
        return value;
    }

    /**
     * Get list of users with the associated role
     * @param name Role to find users for
     */
    public role(name: string): IBuildingRoleUser[] {
        return [...(this._roles[name] || [])];
    }
    /**
     * Get list of the names of available user role lists
     */
    public get role_names(): string[] {
        return Object.keys(this._roles).filter(i => this._roles.hasOwnProperty(i));
    }
    /** Map of the locker ID arrays */
    public get lockers(): LockerMap {
        return { ...(this._lockers || {}) };
    }
    /** Map of important system ids for the building */
    public get systems(): HashMap<string> {
        return { ...(this._systems || {}) };
    }
    /** Map of important phone numbers for the building */
    public get phone_numbers(): HashMap<string> {
        return { ...(this._phone_numbers || {}) };
    }
    /** Real coordinates */
    public get location(): ICoordinates {
        return { ...this._location };
    }
    /** List of zones associated with the building */
    public get zones(): string[] {
        return [...(this._zones || [])];
    }
    /** Searchable map features */
    public get searchables(): IMapFeature[] {
        return [...(this._searchables || [])];
    }

    /**
     * Get search map feature for the given level ID
     * @param level_id ID of level to grab features for
     */
    public featuresForLevel(level_id: string): IMapFeature[] {
        return (this._searchables || []).filter(i => i.level_id === level_id);
    }

    /**
     * Building objects are readonly and cannot be changed
     */
    public async save(): Promise<Building> {
        throw new Error('Building objects are readonly and cannot be changed');
    }

    /**
     * Building objects are readonly and cannot be deleted
     */
    public async delete(): Promise<void> {
        throw new Error('Building objects are readonly and cannot be deleted');
    }
}
