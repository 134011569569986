import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApplicationService } from '../../services/app.service';
import { BaseComponent } from '../../shared/base.component';
import { Space } from '../../services/data/spaces/space.class';
import { HashMap } from '../../shared/utilities/types.utilities';
import { timeToDate } from '../../shared/utilities/general.utilities';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-booking-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class BookingPanelComponent extends BaseComponent implements OnInit {
    /** ID of the active system */
    @Input() public system_id: string;
    /** Whether the status is on the right side */
    @Input() public reverse: boolean;
    /** Whether to show the time at the bottom of the status */
    @Input() public show_time = true;
    /** Name of the connected system */
    public space_name: string;
    /** Space with which to display bookings for */
    public space: Space;
    /** Number of seconds before a meeting can be started, being truthy enables start/stop and auto-cancellations */
    public pending_timeout: number;
    /** Number of seconds after the start of a meeting when it is auto-cancelled, overrides `timeout` value */
    public cancel_timeout: number;
    /** Timestamp of the last started meeting as ms since UTC epoch */
    public last_started: number;
    /** Whether to hide booking related details */
    public hide_all: boolean;
    /** Whether user interaction is enabled for the panel */
    public interactive = true;
    /** Whether status display is on the right side of the UI */
    public flip_status: boolean;
    /** Whether bookings can be cancelled if they timeout */
    public can_cancel: boolean;
    /** Time of the last cancelled meeting in ms since UTC epoch */
    private last_cancel: number;
    /** Start time of the business day with format `HH:mm` */
    public business_start: string;
    /** End time of the business day with format `HH:mm` */
    public business_end: string;
    /** Whether to show full room name when it overflows */
    public show_title: boolean;
    /** Whether to show the status display string */
    public hide_status: boolean;
    /** Override mapping for status values */
    public status_overrides: HashMap<string>;
    /** Status of websocket */
    public websocket_connected: boolean;

    /** Current status of the active system */
    private _status: 'pending' | 'available' | 'unavailable';

    /** Get the display value for the status */
    public get status_display(): string {
        if (!this.websocket_connected && !this._service.setting('show_status_when_disconnected')) {
            return ' ';
        }
        if (this.status_overrides && Object.keys(this.status_overrides).length === 1) {
            this._status = Object.keys(this.status_overrides)[0] as any;
        }
        const status = (!this.hide_all ? this._status : null) || 'not-bookable';
        return (this.status_overrides || [])[status] || this._status;
    }

    /** Current status of the active system */
    public get status(): 'pending' | 'available' | 'unavailable' | 'not-bookable' {
        if (!this.websocket_connected && !this._service.setting('show_status_when_disconnected')) {
            return 'not-bookable';
        }
        // Perform any status overrides
        this.updateStatus();
        if (
            this.status_overrides &&
            Object.keys(this.status_overrides).length === 1 &&
            (!this.space.current || this._status !== 'unavailable')
        ) {
            this._status = Object.keys(this.status_overrides)[0] as any;
        }
        return this._status;
    }
    /** Whether booking details should be shown */
    public get show_details(): boolean {
        return this.websocket_connected || !!this._service.setting('show_status_when_disconnected');
    }

    /** Display string for the current time */
    public get display_time(): string {
        return dayjs().format('h:mm A');
    }

    constructor(public _service: ApplicationService, public _route: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('app_ready', this._service.initialised.subscribe((is_ready) => {
            if (is_ready) {
                this.subscription(
                    'route.params',
                    this._route.paramMap.subscribe(params => {
                        if (params.has('system_id')) {
                            this.system_id = params.get('system_id');
                            this._service.set('system', this.system_id);
                        }
                    })
                );
                this.timeout('websocket', () => {
                    this._service.websocket.status(status => {
                        this.websocket_connected = status;
                    });
                });
                this.unsub('app_ready');
            }
        }));
    }

    /**
     * Update the bookings for the active space
     * @param bookings Array of raw booking data
     */
    public updateBookings(bookings: HashMap[]): void {
        this.space = new Space(this._service.Spaces, {
            id: this.system_id,
            name: this.space_name,
            bookings
        });
    }

    /**
     * Update the current status of the active space
     */
    public updateStatus(): void {
        if (this.hide_all) {
            this._status = null;
        } else {
            if (this.space && (this.space.current || this.space.next)) {
                const booking = this.space.current || this.space.next;
                this._status = this.space.current ? 'unavailable' : 'available';
                if (this.pending_timeout) {
                    const now = dayjs();
                    const date = dayjs(booking.date).startOf('m');
                    if (this.last_started >= now.valueOf() || this.last_started >= date.valueOf()) {
                        this._status = 'unavailable';
                    } else {
                        // Only meetings with durations less than 8 hours can be pending or cancelled
                        const pending_start = date.subtract(this.pending_timeout, 's');
                        const pending_end = date.add(this.cancel_timeout || this.pending_timeout, 's');
                        const end = date.add(booking.duration, 'm');
                        if (now.isAfter(pending_start, 's') && now.isBefore(pending_end, 's')) {
                            this._status = 'pending';
                        } else if (now.isAfter(pending_end, 's') && now.isBefore(end, 'm')) {
                            this._status = 'pending';
                            const business_start = dayjs(timeToDate(this.business_start || '08:00'));
                            const business_end = dayjs(timeToDate(this.business_end || '17:30'));
                            // Check if within business hours before cancelling the meeting
                            if (now.isBefore(business_start, 'm') || now.isAfter(business_end, 'm')) {
                                return;
                            }
                            this.endMeeting('timeout');
                        }
                    }
                }
            } else {
                this._status = 'available';
            }
        }
    }

    /**
     * Show full room name if overflowing
     */
    public showTitle() {
        this.show_title = true;
        this.timeout('hide_title', () => (this.show_title = false), 3000);
    }

    /**
     * Execute end meeting logic on engine driver
     * @param reason Reason for ending the meeting early
     */
    private endMeeting(reason: string = 'user_input') {
        if (this.space && this.can_cancel !== false) {
            const meeting = this.space.current || this.space.next;
            const module = this._service.Systems.get(this.space.id, 'Bookings');
            if (meeting.duration >= 8 * 60 && reason === 'timeout') {
                reason = 'hide multi-day';
            }
            if (meeting && module && meeting.date !== this.last_cancel) {
                const date = dayjs(meeting.date);
                module.exec('cancel_meeting', [meeting.date, reason]).then(
                    _ => {
                        this._service.Analytics.event(
                            'Checkin',
                            'cancelled',
                            `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')} | ${reason}`
                        );
                        this.last_cancel = meeting.date;
                    },
                    e => {
                        this._service.notifyError(`Error cancelling meeting. ${e}`);
                        this._service.Analytics.event(
                            'Checkin',
                            'cancel-failed',
                            `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')} | ${reason}`
                        );
                    }
                );
            }
        }
    }
}
