<div class="booking-actions">
    <div class="actions" *ngIf="space">
        <button widget class="end large action" *ngIf="status === 'endable'" (tapped)="confirmEnd()" (contextmenu)="$event.preventDefault()">
            <div class="icon">
                <i class="material-icons">event_busy</i>
            </div>
            <div class="text">End</div>
        </button>
        <button widget class="start large action" *ngIf="status === 'pending'" (tapped)="startMeeting()" (contextmenu)="$event.preventDefault()">
            <div class="icon">
                <i class="material-icons">event_available</i>
            </div>
            <div class="text">Start</div>
        </button>
        <button widget class="book large action" *ngIf="!no_booking" (tapped)="book()" (contextmenu)="$event.preventDefault()">
            <div class="icon">
                <i class="material-icons">add</i>
            </div>
            <div class="text">Book</div>
        </button>
    </div>
    <div class="bindings" *ngIf="space">
        <i binding [sys]="space.id" mod="Bookings" bind="booking_disabled" [(model)]="no_booking"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="last_meeting_started" [(model)]="last_started"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="is_endable" [(model)]="is_endable"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="timeout" [(model)]="pending_timeout"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="cancel_timeout" [(model)]="cancel_timeout"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="ask_start" [(model)]="ask_start"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="ask_end" [(model)]="ask_end"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="default_title" [(model)]="default_title"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="min_duration" [(model)]="min_duration"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="max_duration" [(model)]="max_duration"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="business_start" [(model)]="business_start"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="business_end" [(model)]="business_end"></i>
    </div>
</div>
