import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, map, catchError } from 'rxjs/operators';

import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../base.component';
import { ApplicationService } from '../../../services/app.service';
import { User } from '../../../services/data/users/user.class';
import { filterList, matchToHighlight } from '../../utilities/general.utilities';
import { FORM_FIELDS } from '../../globals/form-fields';

@Component({
    selector: 'custom-user-search-field',
    templateUrl: './user-search-field.component.html',
    styleUrls: ['./user-search-field.component.scss']
})
export class CustomUserSearchFieldComponent extends BaseComponent implements OnInit {
    /** List of users from an API search */
    public filtered_list: User[] = [];
    /** List of users from an API search */
    public search_results$: Observable<User[]>;
    /** Whether to show list tooltip */
    public show: boolean;
    /** Search input field value */
    public search_str: string;
    /** Whether a query is in progress */
    public searching: boolean;
    /** Subject holding the value of the search */
    private search$ = new Subject<string>();

    constructor(protected field: ADynamicFormField, protected group: FormGroup, private service: ApplicationService) {
        super();
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.field.references && this.field.references.length > 0) {
                const control = this.group.controls[this.field.references[0]];
                if (control) {
                    this.subscription('reference', control.valueChanges.subscribe(() => this.updateDisplay()));
                }
            }
            this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        }
        // Listen for input changes
        this.search_results$ = this.search$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(query => {
                this.searching = true;
                this.show = true;
                return query.length >= 3 ? (this.service.Users.query({ q: query.slice(0, 3), cache: 60 * 1000 }) as Promise<User[]>) : Promise.resolve([]);
            }),
            catchError((err) => of([])),
            map(list => {
                this.searching = false;
                return filterList(this.search_str, list, ['name', 'email']);
            })
        );
        // Process API results
        this.search_results$.subscribe(list => {
            this.filtered_list = list;
            this.filtered_list.forEach((i: any) => {
                i.match_name = matchToHighlight(i.match_name);
                i.match_email = matchToHighlight(i.match_email);
                return i;
            });
        });
    }

    /**
     * Search for a user that matches the filter string
     * @param filter String to filter users on
     */
    public search(filter: string) {
        this.search_str = filter;
        this.search$.next(filter);
    }

    /**
     * Update input display string
     */
    public updateDisplay() {
        const value = this.field.control.value;
        this.search_str = value ? value.name : '';
    }

    /**
     * Reset the display value for the search input
     */
    public reset() {
        this.timeout('reset', () => {
            const value = this.field.control.value;
            this.search_str = value ? value.name : '';
            this.show = false;
        });
    }

    /**
     * Set the active user
     * @param user New active user
     */
    public select(user: User) {
        if (user) {
            this.field.setValue(user);
        }
        this.timeout('hide', () => {
        this.show = false;
        }, 100);
    }
}

FORM_FIELDS.host = CustomUserSearchFieldComponent;
