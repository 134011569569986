import { Component } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

import { BaseComponent } from '../../base.component';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { getFreeBookingSlots } from '../../../services/data/bookings/booking.utilities';

import * as dayjs from 'dayjs';
import { FORM_FIELDS } from '../../globals/form-fields';

interface TimeSlot {
    id: string;
    name: string;
    value?: number;
}

@Component({
    selector: 'custom-time-field',
    templateUrl: './time-field.component.html',
    styleUrls: ['./time-field.component.scss']
})
export class CustomTimeFieldComponent extends BaseComponent {
    /** Form Control holding date data */
    public date_control: AbstractControl;
    /** Form Control holding space data */
    public space_control: AbstractControl;
    /** Step size for available times */
    public block_size = 5;
    /** Step size for available times */
    public min_size = this.block_size * 6;
    /** Step size for available times */
    public active_time: TimeSlot;
    /** List of selectable times */
    public times: TimeSlot[];

    constructor(protected field: ADynamicFormField, public group: FormGroup) {
        super();
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.date) {
                this.date_control = this.group.controls.date;
                this.subscription('date', this.date_control.valueChanges.subscribe(() => this.updateDisplay(true)));
            }
            if (this.group.controls.room || this.group.controls.space) {
                this.space_control = this.group.controls.room || this.group.controls.space;
                this.subscription('space', this.space_control.valueChanges.subscribe(() => this.updateDisplay()));
            }
            this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        }
        this.calculateAvailableTimes();
        if (this.date_control) {
            const value = this.date_control.value;
            if (!this.times.find(block => block.value === value)) {
                this.setValue(this.times[0]);
            }
        }
    }

    /**
     * Update the active value displayed on the dropdown
     * @param from_ref Whether new value was generated from a reference field
     */
    public updateDisplay(from_ref: boolean = false) {
        if (this.times) {
            for (const time of this.times) {
                if (this.field.getValue() === time.id) {
                    this.active_time = time;
                    break;
                }
            }
            if (!this.field.getValue()) {
                // Initialise with date time set on date control
                if (this.date_control) {
                    let date = dayjs(this.date_control.value);
                    date = date.minute(Math.ceil(date.minute() / 5) * 5);
                    const date_str = date.format('HH:mm');
                    for (const time of this.times) {
                        if (date.valueOf() - time.value <= 0) {
                            this.active_time = time;
                            this.setValue(time, true);
                            break;
                        }
                    }
                }
                // Set to default value
                if (!this.field.getValue()) {
                    this.active_time = this.times[0];
                    this.setValue(this.times[0], from_ref);
                }
            }
        }
    }

    /**
     * Generate a list of times selectable by the user
     */
    public calculateAvailableTimes() {
        this.times = [];
        let date = dayjs().startOf('m');
        const book_ahead = this.field.metadata ? this.field.metadata.book_ahead || 660 : 660; // 11 hours default
        const min_duration = this.field.metadata ? this.field.metadata.min_duration || 30 : 30;
        if (this.space_control) {
            // Get times based off available times in space
            const slots = getFreeBookingSlots(this.space_control.value.bookings, min_duration);
            date = date.minute(Math.ceil(date.minute() / this.block_size) * this.block_size);
            const end = dayjs(date)
                .add(book_ahead, 'm')
                .endOf('m');
            for (; date.isBefore(end, 's'); date = date.add(this.block_size, 'm')) {
                const value = date.valueOf();
                if (slots.find(i => i.start <= value && value < i.end && dayjs(i.end).diff(date, 'm') >= min_duration)) {
                    this.times.push({ id: date.format('HH:mm'), name: date.format('h:mm A'), value: date.valueOf() });
                }
            }
        } else {
            // Get times from now
            if (date.minute() % this.block_size !== 0) {
                this.times.push({ id: date.format('HH:mm'), name: date.format('h:mm A') });
            }
            date = date.minute(Math.ceil(date.minute() / this.block_size) * this.block_size);
            const end = dayjs(date).add(book_ahead, 'm');
            for (; date.isBefore(end); date = date.add(this.block_size, 'm')) {
                this.times.push({ id: date.format('HH:mm'), name: date.format('h:mm A'), value: date.valueOf() });
            }
        }
        this.updateDisplay();
    }

    /**
     * Update the value of the form field
     * @param value New value
     * @param from_ref Whether new value was generated from a reference field
     */
    public setValue(slot: TimeSlot, from_ref: boolean = false) {
        this.field.setValue(slot.id);
        if (this.date_control && !from_ref) {
            const time = dayjs(slot.value);
            const date = dayjs(this.date_control.value)
                .hour(time.hour())
                .minute(time.minute())
                .startOf('m');
            this.date_control.setValue(date.valueOf());
        }
    }
}

FORM_FIELDS.time = CustomTimeFieldComponent;
