
import { Component, Input } from '@angular/core';

import { Space } from '../../../services/data/spaces/space.class';
import { getNextFreeBookingSlot } from 'src/app/services/data/bookings/booking.utilities';

import * as dayjs from 'dayjs';

@Component({
    selector: 'panel-space-details',
    templateUrl: './space-details.component.html',
    styleUrls: ['./space-details.component.scss']
})
export class PanelSpaceDetailsComponent {
    /** Space with which to display bookings for */
    @Input() public space: Space;
    /** Secondary title for the space */
    public title: string;
    /** Description for the space */
    public description: string;
    /** Image that suppliments the description for the space */
    public icon: { class?: string, value?: string, src?: string };

    /** Whether to hide the booking timing details */
    public hide_availability: boolean;
    /** Whether to hide the current booking title */
    public hide_title: boolean;
    /** Whether to hide booking details(title and host) */
    public hide_details: boolean;
    /** Whether to hide booking host */
    public hide_user: boolean;

    /** Start and end of the next available booking slot */
    public get next_free(): { start: string, end: string } {
        const block = getNextFreeBookingSlot(this.space.bookings, undefined, 15);
        const now = dayjs();
        const start = dayjs(block.start);
        const end = dayjs(block.end);
        return {
            start: start.isSame(now, 'd') && start.isAfter(now, 'm') ? start.format('h:mm A') : '',
            end: end.isSame(now, 'd') && end.isAfter(now, 'm') ? end.format('h:mm A') : ''
        }
    }
}

