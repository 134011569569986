<div class="modal booking" widget [class.is-loading]="loading" [@show] *ngIf="!closing">
    <div class="header">
        <div class="text">New Booking</div>
    </div>
    <div class="body" *ngIf="!loading">
        <div class="form" *ngIf="booking">
            <a-dynamic-form [fields]="booking.form_fields"></a-dynamic-form>
        </div>
    </div>
    <div class="body" *ngIf="loading">
        <div class="info-block">
            <div class="icon">
                <a-spinner circle-ring color="#000"></a-spinner>
            </div>
            <div class="text">Processing request...</div>
        </div>
    </div>
    <div class="footer" *ngIf="!loading">
        <button widget name="close" (tapped)="close()">Cancel</button>
        <button widget name="save" (tapped)="save()">Save</button>
    </div>
</div>