import { Component, OnInit } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { OverlayItem } from '@acaprojects/ngx-overlays';

import { BaseComponent } from '../../shared/base.component';
import { OVERLAY_REGISTER } from '../../shared/globals/overlay-register';

@Component({
    selector: 'embedded-control-modal',
    templateUrl: './embedded-control-modal.component.html',
    styleUrls: ['./embedded-control-modal.component.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(1000, style({ opacity: 0 }))]),
        ]),
    ],
})
export class EmbeddedControlModalComponent extends BaseComponent implements OnInit {
    /** Control URL to embed into the modal */
    public control_url: string;
    /** Display value for the time remain until the modal automatically closes */
    public countdown: number;

    constructor(private item: OverlayItem) {
        super();
        this.countdown = 30;
    }

    public ngOnInit(): void {
        const data = this.item.data;
        if (data) {
            this.control_url = data.control_url;
        }
        if (!this.control_url) {
            this.close();
        }
        this.countdown = 30;
        this.interval('countdown', () => this.tick(), 1000);
    }

    /**
     * Close the modal
     */
    public close() {
        this.item.close();
        this.countdown = 30;
    }

    /**
     * User confirmation of the content of the modal
     */
    public reset() {
        this.countdown = 30;
        this.interval('countdown', () => this.tick(), 1000);
    }

    /**
     * Decrement countdown and close if 0
     */
    public tick() {
        if (this.countdown <= 0) {
            this.close();
        }
        this.countdown--;
    }
}

OVERLAY_REGISTER.push({ id: 'embeded-control', config: { content: EmbeddedControlModalComponent, config: 'modal' } });
