
import { Component, Input } from '@angular/core';

import { Space } from '../../../services/data/spaces/space.class';
import { BaseComponent } from '../../../shared/base.component';
import { ApplicationService } from '../../../services/app.service';
import { timeToDate } from '../../../shared/utilities/general.utilities';

import * as dayjs from 'dayjs';

export type SpaceStatus = 'pending' | 'available' | 'unavailable' | 'endable';

@Component({
    selector: 'panel-booking-actions',
    templateUrl: './booking-actions.component.html',
    styleUrls: ['./booking-actions.component.scss']
})
export class PanelBookingActionsComponent extends BaseComponent {
    /** Space with which to display bookings for */
    @Input() public space: Space;
    /** Whether booking is allowed */
    public no_booking: boolean = false;
    /** Whether the current booking is able to be ended */
    public is_endable: number;
    /** Number of seconds before a meeting can be started, being truthy enables start/stop and auto-cancellations */
    public pending_timeout: number;
    /** Number of seconds after the start of a meeting when it is auto-cancelled, overrides `timeout` value */
    public cancel_timeout: number;
    /** Timestamp of the last started meeting as ms since UTC epoch */
    public last_started: number;
    /** Whether to ask user to start the meeting */
    public ask_start: boolean;
    /** Whether to ask user to end the meeting */
    public ask_end: boolean;
    /** Time of the last automatic confirmation of start or end */
    public last_confirm: number = 0;
    /** Default title for bookings */
    public default_title: string;
    /** Maximum allowed booking duation */
    public max_duration: number;
    /** Minimum allowed booking duation */
    public min_duration: number;
    /** Start time of the business day with format `HH:mm` */
    public business_start: string;
    /** End time of the business day with format `HH:mm` */
    public business_end: string;

    /** Current status of the active system */
    public get status(): SpaceStatus{
        let status: SpaceStatus = 'available';
            if (this.space && (this.space.current || this.space.next)) {
                const booking = this.space.current || this.space.next;
                status = this.space.current ? 'unavailable' : 'available';
                if (this.pending_timeout) {
                    const now = dayjs().startOf('m').second(1);
                    const business_start = dayjs(timeToDate(this.business_start || '08:00'));
                    const business_end = dayjs(timeToDate(this.business_end || '17:30'));
                    // Check if within business hours before looking at pending logic
                    if (now.isBefore(business_start, 'm') || now.isAfter(business_end, 'm')) {
                        return status;
                    }
                    const date = dayjs(booking.date);
                    if (this.last_started >= now.valueOf() || this.last_started >= date.valueOf()) {
                        if (this.is_endable && this.space.current) {
                            const end = date.add(booking.duration, 'm').startOf('m');
                            const block_end = end.subtract(Math.max(this.is_endable, 300), 's');
                            if (now.isAfter(block_end, 's') && now.isBefore(end, 'm')) {
                                status = 'endable';
                                if (this.ask_end && this.last_confirm < end.valueOf()) {
                                    this.confirmEnd();
                                    this.last_confirm = end.valueOf();
                                }
                            } else {
                                status = 'unavailable';
                            }
                        } else {
                            status = 'unavailable';
                        }
                    } else {
                        const pending_start = date.startOf('m').subtract(this.pending_timeout, 's');
                        const pending_end = date.startOf('m').add(this.cancel_timeout || this.pending_timeout, 's');
                        if (now.isAfter(pending_start, 's') && now.isBefore(pending_end, 's')) {
                            status = 'pending';
                            if (this.ask_start && this.last_confirm < date.valueOf()) {
                                this.confirmStart();
                                this.last_confirm = date.valueOf();
                            }
                        }
                    }
                }
            }
        return status;
    }

    constructor(private service: ApplicationService) {
        super();
    }

    /**
     * Open modal to make a new booking
     */
    public book() {
        this.service.Bookings.new({
            space: this.space,
            title: this.default_title,
            max_duration: this.max_duration,
            min_duration: this.min_duration
        });
    }

    /**
     * Open confirmation modal for starting the meeting
     */
    public confirmStart() {
        this.service.Overlay.open('confirm', {
            config: 'modal',
            data: {
                title: 'Do you wish to start your meeting?',
                body: `If you don't start your meeting it will be cancelled ${this.cancel_timeout / 60} minutes after the start time.`,
                icon: { class: 'material-icons', value: 'play_arrow' }
            }
        }, _ => this.startMeeting());
    }

    /**
     * Execute the logic on the engine driver to start the current or upcoming meeting
     */
    public startMeeting() {
        if (this.space) {
            const meeting = this.space.current || this.space.next;
            const module = this.service.Systems.get(this.space.id, 'Bookings');
            if (meeting && module) {
                const date = dayjs(meeting.date);
                module.exec('start_meeting', [meeting.date]).then(_ => {
                    this.service.Analytics.event('Checkin', 'checked-in', `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')}`);
                }, (e) => {
                    this.service.notifyError(`Error starting meeting. ${e}`);
                    this.service.Analytics.event('Checkin', 'checked-in-failed', `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')}`);
                });
            }
        }
    }

    /**
     * Open confirmation modal for ending the meeting
     */
    public confirmEnd() {
        this.service.Overlay.open('confirm', {
            config: 'modal',
            data: {
                title: 'Are you sure want to end your meeting?',
                body: 'Ending your meeting early will free up this room for others to use',
                icon: { class: 'material-icons', value: 'stop' }
            }
        }, _ => this.endMeeting());
    }

    /**
     * Execute end meeting logic on engine driver
     * @param reason Reason for ending the meeting early
     */
    public endMeeting(reason: string = 'user_input') {
        if (this.space) {
            const meeting = this.space.current || this.space.next;
            const module = this.service.Systems.get(this.space.id, 'Bookings');
            if (meeting && module) {
                const date = dayjs(meeting.date);
                module.exec('cancel_meeting', [meeting.date, reason]).then(_ => {
                    this.service.Analytics.event('Checkin', 'cancelled', `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')} | ${reason}`);
                }, (e) => {
                    this.service.notifyError(`Error starting meeting. ${e}`);
                    this.service.Analytics.event('Checkin', 'cancel-failed', `${this.space.id} at ${date.format('DD MMM YYYY, h:mm A Z')} | ${reason}`);
                });
            }
        }
    }

}

