import { BaseDataClass } from '../base-api.class';
import { HashMap, Identity } from '../../../shared/utilities/types.utilities';
import { Booking } from '../bookings/booking.class';
import { BaseAPIService } from '../base.service';

import * as dayjs from 'dayjs';

export interface ISpaceAvailabilityOptions {
    date?: number;
    duration: number;
    id?: string;
    room_ids?: string;
    bookable?: boolean;
    rooms?: string;
    ignore?: string;
    zone_id?: string;
    clear?: boolean;
}

const OPTION_DEFAULTS: ISpaceAvailabilityOptions = {
    duration: 60
};

export class Space extends BaseDataClass {
    /** Extends name for the space */
    readonly long_name: string;
    /** CSS selector for the location of the space of the level's map */
    readonly map_id: string;
    /** Type of space */
    readonly type: string;
    /** Whether user can search for this space */
    readonly can_search: boolean;
    /** Whether user can open the control URL if available */
    readonly can_control: boolean;
    /** Hourly cost for using the space */
    readonly hourly_rate: number;
    /** Whether space can be booked by users */
    readonly bookable: boolean;
    /** People capacity of the space */
    readonly capacity: number;
    /** Index to force order when sorting multiple spaces */
    readonly sort_priority: number;
    /** Setup time for bookings in this space */
    readonly setup: number;
    /** Breakdown time for bookings in this space */
    readonly breakdown: number;
    /** Last returned availability result */
    readonly was_available: boolean;

    /** Level in which the spaces is associated */
    private _level: HashMap;
    /** Bookings associated with the space */
    private _bookings: string[];
    /** Engine zones associated with the space */
    private _zones: string[];
    /** List of equipment available in the space */
    private _extras: Identity[];
    /** List of ids for linked spaces */
    private _linked_spaces: string[];

    constructor(protected service: BaseAPIService<Space>, raw_data: HashMap = {}) {
        super(service, raw_data);
        const settings = raw_data.settings || {};
        this._level = this.service.parent.Organisation.levelWithID(raw_data.zones) || { settings: {} };

        this.long_name = settings.long_name || raw_data.long_name;
        this.map_id = settings.map_id || raw_data.map_id;
        this.type = settings.book_type || this._level.book_type || this._level.settings.book_type || raw_data.book_type || raw_data.type || 'book';
        this.can_search = settings.searchable || raw_data.searchable || raw_data.can_search || false;
        this.can_control = settings.controlable || raw_data.controlable || raw_data.can_control || false;
        this.hourly_rate = settings.cost_hour || raw_data.cost_hour || raw_data.hourly_rate || 0;
        this.bookable = settings.bookable || raw_data.bookable || false;
        this.setup = ((settings.setup || 0) / 60) || raw_data.setup || 0;
        this.breakdown = ((settings.breakdown || 0) / 60) || raw_data.breakdown || 0;
        this.capacity = raw_data.capacity || 0;
        this.was_available = settings.available || raw_data.available || raw_data.was_available || false;

        this._zones = raw_data.zones || [];
        const raw_bookings = settings.bookings || raw_data.bookings || [];
        this._bookings = raw_bookings.map(i => i.id);
        if (this.service.parent && this.service.parent.Bookings) {
            if (raw_bookings) {
                const id_list = [];
                raw_bookings.forEach(booking => {
                    let id = booking.id;
                    this.service.parent.Bookings.removeFrom(this.id, [id], 'class');
                    id = this.service.parent.Bookings.addFrom(this.id, booking, 'class');
                    id_list.push(id);
                });
                this._bookings = id_list;
            }
        }
        this._extras = [];
        this._linked_spaces = settings.linked_rooms || raw_data.linked_rooms || raw_data.linked_spaces || [];
    }

    /** Whether the space has a booking in progress */
    public get in_use(): boolean {
        return !!this.current;
    }

    /** Get any current in progress meeting */
    public get current(): Booking {
        const bookings = this.bookings;
        for (const bkn of bookings) {
            const status = bkn.status;
            if (status === 'in_progress' || status === 'started') {
                return bkn;
            }
        }
        return null;
    }

    /** Get the next upcoming meeting */
    public get next(): Booking {
        const now = dayjs().valueOf();
        const bookings = this.bookings;
        for (const bkn of bookings) {
            if (bkn.date > now) {
                return bkn;
            }
        }
        return null;
    }

    /** List of stored bookings for the space */
    public get bookings(): Booking[] {
        const booking_list = this.service && this.service.parent && this.service.parent.Bookings
            ? this.service.parent.Bookings.list((booking) => this._bookings.indexOf(booking.id) >= 0) || []
            : [];
        return booking_list.sort((a, b) => a.date - b.date);
    }

    /** List of bookings for today */
    public get todays_bookings(): Booking[] {
        return this.bookingsFor(dayjs().valueOf());
    }

    /** List of ids for linked spaces */
    public get linked_spaces() {
        return [...this._linked_spaces];
    }

    /** Level in which the space is associated */
    public get level(): HashMap {
        return this._level;
    }

    /** Engine zones associated with the space */
    public get zones(): string[] {
        return [...this._zones];
    }

    /**
     * Get bookings for space
     * @param date Date to filter bookings on
     */
    public bookingsFor(date?: number): Booking[] {
        if (date) {
            const day = dayjs(date);
            return this.bookings.filter(i => dayjs(i.date).isSame(day, 'd'));
        } else {
            return this.bookings;
        }
    }

    /**
     * Whether the space is available.
     */
    public isAvailable(options?: ISpaceAvailabilityOptions): Promise<boolean> {
        options = options
            ? { ...OPTION_DEFAULTS, ...options, room_ids: this.id }
            : { ...OPTION_DEFAULTS, room_ids: this.id };
        return new Promise((resolve) => {
            (this.service as any).available(options).then((list: Space[]) => {
                for (const rm of list) {
                    if (rm.id === this.id) {
                        return resolve(true);
                    }
                }
                resolve(false);
            }, () => resolve(false));
        });
    }

    /**
     * Make a copy of this object
     */
    public clone(): Space {
        return new Space(this.service, this);
    }

    /**
     * Make a copy of this object without identification data
     */
    public duplicate(): Space {
        return new Space(this.service, { ...this, id: null, email: null, _bookings: [] });
    }
}
