import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { OverlayItem } from '@acaprojects/ngx-overlays';

import { ApplicationService } from '../../services/app.service';
import { BaseComponent } from '../../shared/base.component';
import { Booking } from '../../services/data/bookings/booking.class';
import { OVERLAY_REGISTER } from '../../shared/globals/overlay-register';

import * as dayjs from 'dayjs';

export interface BookingData {
    booking: Booking;
    on_error: () => void;
    close: () => void;
}

@Component({
    selector: 'booking-modal',
    templateUrl: './booking-modal.component.html',
    styleUrls: ['./booking-modal.component.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(100%) scale(0)' }),
                animate(200, style({ opacity: 1, transform: 'translateX(0%) scale(1)' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateX(0%) scale(1)' }),
                animate(
                    200,
                    style({
                        opacity: 0,
                        transform: 'translateX(-100%) scale(0)'
                    })
                )
            ])
        ])
    ]
})
export class BookingModalComponent extends BaseComponent implements OnInit {
    /** Whether modal is closing */
    public closing: boolean;
    /** New Booking instance */
    public booking: Booking;
    /** Whether the modal is processing a booking request */
    public loading: boolean;

    constructor(private item: OverlayItem, private service: ApplicationService) {
        super();
    }

    public ngOnInit(): void {
        this.booking = new Booking(this.service.Bookings, {
            date: this.item.data.date,
            form_metadata: { ...this.item.data }
        });
        const space_field = this.booking.form_fields.find(field => field.key === 'space');
        if (this.item.data.space && space_field) {
            space_field.setValue(this.item.data.space);
        }
        const date_field = this.booking.form_fields.find(field => field.key === 'date');
        if (date_field) {
            date_field.setValue(this.item.data.date || dayjs().valueOf());
        }
        const title_field = this.booking.form_fields.find(field => field.key === 'title');
        if (title_field) {
            title_field.setValue(this.item.data.title || '');
        }
    }

    /**
     * Closes the modal
     */
    public close() {
        this.closing = true;
        this.timeout('close', () => this.item.close());
    }

    /**
     * Post form data
     */
    public save() {
        const fields = this.booking.form_fields;
        fields.forEach(i => i.control.markAsDirty());
        const valid = fields.reduce(
            (fields_valid, field) =>
                fields_valid &&
                (field.control.valid ||
                    (field.children &&
                        field.children.length &&
                        field.children.reduce(
                            (sub_fields_valid, sub_field) => sub_fields_valid && sub_field.control.valid,
                            true
                        ))),
            true
        );
        if (this.booking && valid) {
            this.loading = true;
            this.item.post('finish', {
                booking: this.booking,
                on_error: () => (this.loading = false),
                close: () => this.close()
            });
        } else {
            console.log(
                'Invalid form fields. Valid states:',
                fields.map(field => `${field.key}:${field.control.valid}`)
            );
        }
    }
}

OVERLAY_REGISTER.push({
    id: 'booking',
    config: { content: BookingModalComponent, config: 'modal' }
});
