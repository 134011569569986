import { Component, Input } from '@angular/core';
import { Space } from '../../../services/data/spaces/space.class';
import { ApplicationService } from '../../../services/app.service';

@Component({
    selector: 'panel-custom-actions',
    templateUrl: './custom-actions.component.html',
    styleUrls: ['./custom-actions.component.scss']
})
export class PanelCustomActionsComponent {
    /** Space with which to display bookings for */
    @Input() public space: Space;
    /** Control URL of the active space */
    public control_url: string;
    /** Whether the user is able to open the control URL */
    public can_control: boolean;
    /** Whether space has catering */
    public catering: boolean;

    constructor(private service: ApplicationService) {}

    /**
     * Open confirmation modal for ending the meeting
     */
    public confirmWaiterCall() {
        this.service.Overlay.open('confirm', {
            config: 'modal',
            data: {
                title: 'Call a waiter to your room?',
                body: 'Call a waiter to your room to order catering',
                icon: { class: 'material-icons', value: 'call' }
            }
        }, _ => this.callWaiter());
    }

    /**
     * Execute call waiter logic on engine driver
     */
    public callWaiter() {
        if (this.space) {
            const module = this.service.Systems.get(this.space.id, 'Bookings');
            if (module) {
                module.exec('waiter_call', []).then(_ => {
                    this.service.Analytics.event('Catering', 'waiter-call', (new Date).toISOString());
                }, (e) => {
                    this.service.Analytics.event('Catering', 'waiter-call-failed', (new Date).toISOString());
                    this.service.notifyError(`Error calling waiter. ${e}`);
                });
            }
        }
    }

    /**
     * Open modal to show embeded control URL
     */
    public openControl() {
        this.service.Analytics.event('Control', 'open-view', (new Date).toISOString());
        this.service.Overlay.open('embeded-control', {
            config: 'modal',
            data: {
                control_url: this.control_url
            }
        });
    }
}
