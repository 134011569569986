import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { ComposerService } from '@acaprojects/ngx-composer';
import { ComposerOptions } from '@acaprojects/ts-composer';
import { AOverlayService } from '@acaprojects/ngx-overlays';
import { GoogleAnalyticsService } from '@acaprojects/ngx-google-analytics';

import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

import { BaseClass } from '../shared/base.class';
import { SettingsService, ConsoleStream } from './settings.service';
import { HashMap } from '../shared/utilities/types.utilities';

import { HotkeysService } from './hotkeys.service';
import { OrganisationService } from './data/organisation/organisation.service';
import { UsersService } from './data/users/users.service';
import { BookingsService } from './data/bookings/bookings.service';
import { SpacesService } from './data/spaces/spaces.service';
import { SystemsManagerService } from './data/systems-manager/systems-manager.service';
import { OVERLAY_REGISTER } from '../shared/globals/overlay-register';
import { ComposerSettings } from '../shared/utilities/settings.interfaces';
import { first } from 'rxjs/operators';

declare global {
    interface Window {
        application: ApplicationService;
        mock: {
            enabled: boolean;
            backend: any;
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseClass {
    /** List of previous routes for return navigation */
    private _route_trail: string[] = [];
    /** Map of state variables for Service */
    protected _subjects: { [key: string]: BehaviorSubject<any> | Subject<any> } = {};
    /** Map of observables for state variables */
    protected _observers: { [key: string]: Observable<any> } = {};
    /** Whether the application has stablised */
    private _stable: boolean;

    /** Whether the application has stablised */
    public get is_stable(): boolean {
        return this._stable || false;
    }

    constructor(
        private _app_ref: ApplicationRef,
        private _zone: NgZone,
        private _title: Title,
        private _router: Router,
        private _cache: SwUpdate,
        private _settings: SettingsService,
        private _overlay: AOverlayService,
        private _composer: ComposerService,
        private _analytics: GoogleAnalyticsService,
        private _hotkeys: HotkeysService,
        private _systems: SystemsManagerService,
        private _organisation: OrganisationService,
        private _users: UsersService,
        private _bookings: BookingsService,
        private _spaces: SpacesService
    ) {
        super();
        this._organisation.parent = this._users.parent = this._bookings.parent
            = this._spaces.parent = this._systems.parent = this;
        this.set('system', null);
        this._app_ref.isStable.pipe(first(_ => _)).subscribe(() => {
            this._zone.run(() => {
                this._stable = true;
                this.log('APP', `Application has stablised.`);
                this.setupCache();
                this.waitForSettings();
                this.registerOverlays();
            });
        });
    }

    /** Overlay service */
    public get Overlay(): AOverlayService {
        return this._overlay;
    }

    /** Analytics service */
    public get Analytics() {
        return this._analytics;
    }

    /** Hotkeys service */
    public get Hotkeys() {
        return this._hotkeys;
    }

    /** Systems Manager service */
    public get Systems() {
        return this._systems;
    }

    /** Organisation service */
    public get Organisation() {
        return this._organisation;
    }

    /** Users service */
    public get Users() {
        return this._users;
    }

    /** Bookings service */
    public get Bookings() {
        return this._bookings;
    }

    /** Spaces service */
    public get Spaces() {
        return this._spaces;
    }

    /**
     * Get a setting from the settings service
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        return this._settings.get(key);
    }

    /** Name of the application */
    public get name() {
        return this._settings.app_name;
    }

    /**
     * Title of the page
     */
    public set title(value: string) {
        const title_suffix = this.setting('app.title');
        this._title.setTitle(`${value ? value + ' | ' : ''}${title_suffix}`);
    }

    /**
     * Title of the page
     */
    public get title(): string {
        return this._title.getTitle();
    }

    /** Root API Endpoint */
    public get endpoint() {
        return `/api/staff/`;
    }

    /** Root API Endpoint for engine */
    public get engine_endpoint() {
        return `${this._composer.auth.api_endpoint}/`;
    }

    /** Get websocket */
    public get websocket() {
        return this._composer.realtime;
    }

    /**
     * Create notification popup
     * @param type CSS Class to add to the notification
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notify(type: string, msg: string, action?: string, on_action?: () => void): void {
        const content = `<div class="icon"><i class="material-icons"></i></div><div class="text">${msg}</div>`;
        this._overlay.notify(content, action, on_action, type);
    }

    /**
     * Create success notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifySuccess(msg: string, action?: string, on_action?: () => void): void {
        this.notify('success', msg, action, on_action);
    }

    /**
     * Create success notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifyError(msg: string, action?: string, on_action?: () => void): void {
        this.notify('error', msg, action, on_action);
    }

    /**
     * Create info notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifyInfo(msg: string, action?: string, on_action?: () => void): void {
        this.notify('info', msg, action, on_action);
    }

    /**
     * Log data to the browser console
     * @param type Type of message
     * @param msg Message body
     * @param args array of argments to log to the console
     * @param stream Stream to emit the console on. 'debug', 'log', 'warn' or 'error'
     * @param force Whether to force message to be emitted when debug is disabled
     */
    public log(type: string, msg: string, args?: any, stream: ConsoleStream = 'debug', force: boolean = false): void {
        this._settings.log(type, msg, args, stream, force);
    }

    /**
     * Navigate to the given path
     * @param path Path or array of path parts
     * @param query Key value pairs to add to the URL as query parameters
     */
    public navigate(path: string | string[], query?: HashMap): void {
        const route = path instanceof Array ? [...path] : [path];
        this._route_trail.push(this._router.url);
        this._router.navigate(route, query ? { queryParams: query } : { preserveFragment: true });
    }

    /**
     * Navigate to the previous location in the route trail
     */
    public navigateBack(): void {
        if (this._route_trail && this._route_trail.length > 0) {
            const path = this._route_trail.pop();
            this._router.navigate([path]);
        } else {
            this._router.navigate(['']);
        }
    }

    /**
     * Get the current value of the named property
     * @param name Property name
     */
    public get<U = any>(name: string): U {
        return this._subjects[name] && this._subjects[name] instanceof BehaviorSubject
            ? (this._subjects[name] as BehaviorSubject<U>).getValue()
            : null;
    }


    /**
     * Listen to value change of the named property
     * @param name Property name
     * @param next Callback for value changes
     */
    public listen<U = any>(name: string, next: (_: U) => void): Subscription {
        return this._observers[name] ? this._observers[name].subscribe(next) : null;
    }

    /**
     * Update the value of the named property
     * @param name Property name
     * @param value New value
     */
    public set<U = any>(name: string, value: U): void {
        if (!this._subjects[name]) {
            this._subjects[name] = new BehaviorSubject<U>(value);
            this._observers[name] = this._subjects[name].asObservable();
        } else {
            this._subjects[name].next(value);
        }
    }

    /** Wait for settings to be initialised before setting up the application */
    private waitForSettings() {
        // Wait until the settings have loaded before initialising
        this.subscription('setting_setup', this._settings.initialised.subscribe((setup) => {
            if (setup) {
                this.init();
                this.unsub('setting_setup');
            }
        }))
    }

    /**
     * Initialise application services
     */
    private init(): void {
        this.setupComposer();
        // Setup analytics
        this._analytics.enabled = !!this.setting('app.analytics.enabled');
        if (this._analytics.enabled) {
            this._analytics.load(this.setting('app.analytics.tracking_id'));
        }
        this._composer.initialised.pipe(first(_ => _)).subscribe(() => {
            this._users.init();
            this._organisation.init();
            this._bookings.init();
            this._spaces.init();
            this._initialised.next(true);
        });
        // Add service to window if in debug mode
        if (window.debug) {
            window.application = this;
        }
        this._hotkeys.listen(['Shift', 'Backslash'], () => {
            this.navigate('bootstrap', { clear: true });
        });
    }

    /**
     * Initialise the composer library comms
     */
    private setupComposer(): void {
        this.log('SYSTEM', 'Setting up composer...');
        // Get application settings
        const settings: ComposerSettings = this.setting('composer') || {};
        const protocol = settings.protocol || location.protocol;
        const host = settings.domain || location.hostname;
        const port = settings.port || location.port;
        const url = settings.use_domain ? `${protocol}//${host}:${port}` : location.origin;
        const route = settings.route || '';
        const mock = this.setting('mock');
        // Generate configuration object
        const config: ComposerOptions = {
            scope: 'public',
            host: `${host}:${port}`,
            auth_uri: `${url}/auth/oauth/authorize`,
            token_uri: `${url}/auth/token`,
            redirect_uri: `${location.origin}${route}/oauth-resp.html`,
            handle_login: !settings.local_login,
            mock
        };
        if (localStorage) {
            localStorage.setItem('oauth_redirect', location.href);
        }
        this._composer.setup(config);
        this.log('SYSTEM', 'Finsihed setting up composer.');
    }

    /**
     * Setup handler for cache change events
     */
    private setupCache() {
        this.unsub('app_stable');
        this.log('CACHE', `Initialising cache...`);
        if (this._cache.isEnabled) {
            this.log('CACHE', `Listening to cache events...`);
            this._cache.activateUpdate();
            this.subscription('cache_update', this._cache.available.subscribe((event) => {
                const current = `current version is ${event.current.hash}`;
                const available = `available version is ${event.available.hash}`;
                this.log('CACHE', `Update available: ${current} ${available}`);
                this.activateUpdate()
            }));
            setInterval(() => {
                this.log('CACHE', `Checking for updates...`);
                this._cache.checkForUpdate();
            }, 5 * 60 * 1000);
        }
    }

    /**
     * Update the cache and reload the page
     */
    private activateUpdate() {
        if (this._cache.isEnabled) {
            this.log('CACHE', `Activating changes to the cache...`);
            this._cache.activateUpdate().then(() => location.reload(true));
        }
    }

    /**
     * Pre-register available overlays
     */
    private registerOverlays(): void {
        if (OVERLAY_REGISTER) {
            for (const overlay of OVERLAY_REGISTER) {
                this._overlay.register(overlay.id, overlay.config);
            }
        }
    }
}
