import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import './shared/mocks';

import { ComposerModule } from '@acaprojects/ngx-composer';
import { AGoogleAnalyticsModule } from '@acaprojects/ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SharedOverlaysModule } from './overlays/overlays.module';
import { SharedContentModule } from './shared/shared.module';

import { ShellWrapperComponent } from './shell/shell.component';
import { BootstrapComponent } from './shell/bootstrap/bootstrap.component';
import { BookingPanelComponent } from './shell/panel/panel.component';
import { PanelTimelineComponent } from './shell/panel/timeline/timeline.component';
import { PanelSpaceDetailsComponent } from './shell/panel/space-details/space-details.component';
import { PanelBookingActionsComponent } from './shell/panel/booking-actions/booking-actions.component';
import { PanelCustomActionsComponent } from './shell/panel/custom-actions/custom-actions.component';
import { BookingPanelArrayComponent } from './shell/panel-array/panel-array.component';
import { BookingPanelSelectComponent } from './shell/panel-select/panel-select.component';

@NgModule({
    declarations: [
        AppComponent,
        ShellWrapperComponent,
        BootstrapComponent,
        BookingPanelComponent,
        PanelTimelineComponent,
        PanelSpaceDetailsComponent,
        PanelBookingActionsComponent,
        PanelCustomActionsComponent,
        BookingPanelArrayComponent,
        BookingPanelSelectComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ComposerModule,
        AGoogleAnalyticsModule,
        SharedOverlaysModule,
        SharedContentModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        console.log('Production:', !!environment.production);
    }
}
