<div class="custom-actions">
    <div class="actions" *ngIf="space">
        <button widget class="action control" *ngIf="can_control !== false && control_url" (tapped)="openControl()" (contextmenu)="$event.preventDefault()">
            <div class="icon">
                <i class="material-icons">dvr</i>
            </div>
        </button>
        <button widget class="action service" *ngIf="catering" (tapped)="confirmWaiterCall()" (contextmenu)="$event.preventDefault()">
            <div class="icon">
                <i class="material-icons">room_service</i>
            </div>
        </button>
    </div>
    <div class="bindings" *ngIf="space">
        <i binding [sys]="space.id" mod="Bookings" bind="control_url" [(model)]="control_url"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="can_control" [(model)]="can_control"></i>
        <i binding [sys]="space.id" mod="Bookings" bind="has_catering" [(model)]="catering"></i>
    </div>
</div>
