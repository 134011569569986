import { HashMap } from '../../../shared/utilities/types.utilities';
import { generateMockBooking } from '../bookings/booking.utilities';

import * as faker from 'faker';
import { USER_DOMAIN } from '../users/user.utilities';

let SPACE_COUNT: number = 0;

export function generateMockSpace(id?: string, zones?: string[]): HashMap {
    if (!id) {
        id = `space-${SPACE_COUNT++}`;
    }
    const name = `${faker.name.firstName()} ${faker.name.lastName()} Space`;
    const linked = Math.floor(Math.random() * 99999) % 2 === 0 && SPACE_COUNT > 1;
    return {
        id,
        name,
        long_name: `${name} with an long name`,
        map_id: `${SPACE_COUNT}`,
        capacity: Math.floor(Math.random() * 20 + 1) * 2,
        email: `${name.toLowerCase().split(' ').join('.')}@${USER_DOMAIN}`,
        type: faker.commerce.productName(),
        searchable: Math.floor(Math.random() * 99999) % 2 === 0,
        controlable: Math.floor(Math.random() * 99999) % 2 === 0,
        bookable: Math.floor(Math.random() * 99999) % 2 === 0,
        cost_hour: Math.floor(Math.random() * 300) * 100,
        setup: Math.floor(Math.random() * 6) * 5,
        breakdown: Math.floor(Math.random() * 6) * 5,
        zones: zones || ['zone_lvl-0'],
        bookings: Array(10).fill(0).map(i => generateMockBooking()),
        linked_rooms: linked ? [`space-${Math.floor(Math.random() * (SPACE_COUNT - 1))}`] : []
    };
}
