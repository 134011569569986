<div class="background"></div>
<div class="modal control" [@show] (window:touchend)="reset()" (window:mouseup)="reset()">
    <div class="control-frame">
        <iframe [src]="control_url | safe:'resource'"></iframe>
    </div>
</div>
<div class="overlay">
    <button widget class="action countdown" (tapped)="reset()" (contextmenu)="$event.preventDefault()">{{ countdown }}</button>
    <button widget class="action close" (tapped)="close()" (contextmenu)="$event.preventDefault()">
        <div class="icon">
            <i class="material-icons">close</i>
        </div>
    </button>
</div>
