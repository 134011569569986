
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ADropdownsModule } from '@acaprojects/ngx-dropdown';
import { ASpinnerModule } from '@acaprojects/ngx-spinners';
import { AButtonsModule } from '@acaprojects/ngx-buttons';
import { APipesModule } from '@acaprojects/ngx-pipes';
import { AOverlayModule } from '@acaprojects/ngx-overlays';
import { ADynamicFormsModule } from '@acaprojects/ngx-dynamic-forms';
import { ACustomEventsModule } from '@acaprojects/ngx-custom-events';

import { BaseComponent } from './base.component';
import { CustomUserSearchFieldComponent } from './components/user-search-field/user-search-field.component';
import { CustomTimeFieldComponent } from './components/time-field/time-field.component';
import { CustomDurationFieldComponent } from './components/duration-field/duration-field.component';

const COMPONENTS: Type<any>[] = [
    BaseComponent
];

const ENTRY_COMPONENT: Type<any>[] = [
    CustomUserSearchFieldComponent,
    CustomTimeFieldComponent,
    CustomDurationFieldComponent
]

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENT,
    ],
    imports: [
        CommonModule,
        ADropdownsModule,
        ASpinnerModule,
        AButtonsModule,
        AOverlayModule,
        FormsModule,
        ReactiveFormsModule,
        ACustomEventsModule,
        ADynamicFormsModule
    ],
    exports: [
        ADropdownsModule,
        ASpinnerModule,
        AButtonsModule,
        APipesModule,
        AOverlayModule,
        ACustomEventsModule,
        ADynamicFormsModule,
        ...COMPONENTS,
        ...ENTRY_COMPONENT
    ],
    entryComponents: [
        ...COMPONENTS,
        ...ENTRY_COMPONENT
    ]
})
export class SharedContentModule {}
