<div class="user-search" widget>
    <div class="search" a-tooltip [show]="show && search_str && filtered_list.length > 0" (showChange)="show = $event" [content]="list">
        <div class="searchbar">
            <input type="text" [(ngModel)]="search_str" (ngModelChange)="search($event)" (blur)="reset()" placeholder="Search for host..." />
            <div class="spinner" *ngIf="searching">
                <a-spinner circle-ring color="#000"></a-spinner>
            </div>
        </div>
        <ng-template #list>
            <div class="user-list">
                <div class="item" *ngFor="let item of filtered_list" (mouseup)="select(item)" (touchend)="select(item)" feedback>
                    <div class="name" [innerHTML]="item.match_name || item.name"></div>
                    <div class="email" [innerHTML]="item.match_email || item.email"></div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
