<div class="bootstrap">
    <div class="options">
        <div class="header fs-big">
            <h1>Booking Panel Setup</h1>
            <div
                class="toggle"
                feedback
                center="true"
                *ngIf="system_list && system_list.length > 0"
                (tapped)="manual_input = !manual_input"
            >
                <i class="material-icons">{{ manual_input ? 'list' : 'code' }}</i>
            </div>
        </div>
        <div class="body" *ngIf="!loading">
            <ng-container *ngIf="manual_input; else list_desc">
                <p class="description">
                    Input the Engine System ID to bootstrap
                </p>
                <div class="content">
                    <div class="input" [class.focus]="input_focus || system_id">
                        <input [(ngModel)]="system_id" (focus)="input_focus = true" (blur)="input_focus = false" />
                        <div class="placeholder" [class.focus]="input_focus">Engine System ID</div>
                    </div>
                </div>
            </ng-container>
            <ng-template #list_desc>
                <p class="description">
                    Select the relevent system from the available dropdown
                </p>
                <div class="content">
                    <a-dropdown
                        klass="fill"
                        [items]="system_list"
                        [(ngModel)]="selected_system"
                        [options]="{ can_filter: true }"
                        placeholder="Select system"
                    ></a-dropdown>
                </div>
            </ng-template>
        </div>
        <div class="body" *ngIf="loading">
            <div class="info-block">
                <div class="icon">
                    <a-spinner circle-ring color="#000"></a-spinner>
                </div>
                <div class="text">
                    Loading system data...
                </div>
            </div>
        </div>
        <div class="footer" *ngIf="!loading">
            <button
                widget
                [disabled]="(manual_input && !system_id) || (!manual_input && (!selected_system || !selected_system.id))"
                (tapped)="bootstrap()"
            >
                Submit
            </button>
        </div>
    </div>
</div>
