
<div class="shell">
    <div class="view">
        <router-outlet></router-outlet>
    </div>
    <div class="bindings" *ngIf="system_id">
        <i binding [sys]="system_id" mod="Bookings" bind="room_name" [(model)]="system_name" (modelChange)="checkLoading()"></i>
        <i binding [sys]="system_id" mod="Bookings" bind="reload" (modelChange)="reloadAt($event)"></i>
    </div>
    <div class="loading-block" *ngIf="system_id && !system_name">
        <div class="info-block">
            <div class="title">Connecting to system({{system_id}})...</div>
            <div class="text">Attempting to connect to engine server...</div>
            <div class="text message">{{info_messages[message_index]}}</div>
            <div class="icon">
                <a-spinner ring-rotate color="#000"></a-spinner>
            </div>
        </div>
    </div>
    <div class="version" *ngIf="show_version" [class.loading]="system_id && !system_name">
        Version: {{ version }}<br>
        Built: {{ build }}
    </div>
</div>