
import { Injectable } from '@angular/core';
import { ComposerService } from '@acaprojects/ngx-composer';

import { BaseAPIService } from '../base.service';
import { Booking } from './booking.class';
import { Space } from '../spaces/space.class';
import { HashMap } from '../../../shared/utilities/types.utilities';
import { BookingData } from '../../../overlays/booking-modal/booking-modal.component';

import * as dayjs from 'dayjs';

export interface IBookingOptions {
    space: Space;
    date?: number;
    title?: string;
    max_duration?: number;
    min_duration?: number;
}

@Injectable({
    providedIn: 'root'
})
export class BookingsService extends BaseAPIService<Booking> {
    constructor(protected _composer: ComposerService) {
        super(_composer);
        this._name = 'Bookings';
        this._api_route = 'bookings';
        this._compare = (a, b) => !a.id.localeCompare(b.id) || !a.icaluid.localeCompare(b.icaluid);
    }

    /**
     * Open form modal for creating new bookings
     * @param space Space to make booking in
     * @param date Default date on the form
     */
    public new(options: IBookingOptions) {
        if (options.space) {
            this.parent.Overlay.open('booking', {
                config: 'modal',
                data: options as any
            }, (event: { type: string, data: BookingData }) => {
                const booking = event.data.booking.toJSON();
                this.timeout('book', () => {
                    this.create(options.space, booking)
                        .then(() => {
                            this.parent.notifySuccess('Booking successfully created');
                            event.data.close ? event.data.close() : ''
                        }).catch(_ => {
                            this.parent.notifyError(`Error creating meeting. ${_}`);
                            event.data.on_error ? event.data.on_error() : '';
                        });
                }, 1000);
            });
        }
    }

    /**
     * Create new booking with the given properties
     * @param booking New booking data
     */
    private create(space: Space, booking: HashMap) {
        return new Promise((resolve, reject) => {
            if (space) {
                const request = {
                    title: booking.title,
                    count: {
                        staff: booking.num_staff ? +booking.num_staff : 0,
                        partners: booking.num_partners ? +booking.num_partners : 0,
                    },
                    staff_id: '', // data.form.user.staff_code,
                    start: booking.date.valueOf(),
                    end: dayjs(booking.date).add(booking.period.duration, 'm').valueOf(),
                    host: booking.host || booking.user || { name: 'Panel User' }
                };
                const module = this.parent.Systems.get(space.id, 'Bookings');
                if (request && module) {
                    module.exec('create_meeting', [request]).then(() => {
                        this.analyticsEvent('create-success');
                        resolve();
                    }, (err) => {
                        this.analyticsEvent('create-failed');
                        reject(`Error making booking: ${err}`);
                    })
                } else {
                    reject(`No Bookings module found for space ${space.id}`);
                }
            } else {
                reject('No space set for booking');
            }
        });
    }

    protected process(raw_data: HashMap): Booking {
        return new Booking(this, raw_data);
    }
}
