import { BaseDataClass } from '../base-api.class';
import { HashMap } from '../../../shared/utilities/types.utilities';
import { getItemWithKeys } from '../../../shared/utilities/general.utilities';
import { BaseAPIService } from '../base.service';

export class Organisation extends BaseDataClass {
    /** Map of custom settings for the building */
    private _settings: HashMap;

    constructor(protected service: BaseAPIService<Organisation>, raw_data: HashMap) {
        super(service, raw_data);
        this._settings = raw_data.settings || {};
    }

    /**
     * Get a custom building setting
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        const keys = key.split('.');
        const value = getItemWithKeys(keys, this._settings);
        return value;
    }

    /**
     * Building objects are readonly and cannot be changed
     */
    public async save(): Promise<Organisation> {
        throw new Error('Organisation objects are readonly and cannot be changed');
    }

    /**
     * Building objects are readonly and cannot be deleted
     */
    public async delete(): Promise<void> {
        throw new Error('Organisation objects are readonly and cannot be deleted');
    }
}
