import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../../shared/base.component';
import { ApplicationService } from 'src/app/services/app.service';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-booking-panel-array',
    templateUrl: './panel-array.component.html',
    styleUrls: ['./panel-array.component.scss']
})
export class BookingPanelArrayComponent extends BaseComponent implements OnInit {
    /** List of systems to show panels for */
    public systems: string[];
    /** Application logo */
    public logo: string;

    /** Display value for the current time */
    public get time(): string {
        return dayjs().format('h:mm:ss A')
    }

    constructor(private route: ActivatedRoute, private _service: ApplicationService) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('app_ready', this._service.initialised.subscribe((is_ready) => {
            if (is_ready) {
                this.subscription('route.query', this.route.queryParamMap.subscribe((params) => {
                    if (params.has('system_ids')) {
                        this.systems = (params.get('system_ids') || '').split(',');
                    }
                }));
                const logo = this._service.setting('app.logo');
                this.logo = (logo ? logo.inverse : null) || '';
                this.unsub('app_ready');
            }
        }));
    }
}
