
import { Injectable } from '@angular/core';
import { ComposerService } from '@acaprojects/ngx-composer';
import { EngineSystemsService, EngineModuleBinding, EngineSystem } from '@acaprojects/ts-composer';
import { BehaviorSubject } from 'rxjs';

import { ApplicationService } from '../../app.service';
import { BaseClass } from '../../../shared/base.class';

@Injectable({
    providedIn: 'root'
})
export class SystemsManagerService extends BaseClass {
    /** Application service */
    public parent: ApplicationService;
    /** Composer resource service */
    private _resources: EngineSystemsService;
    /** Subject for System list */
    private _list = new BehaviorSubject<EngineSystem[]>([]);

    constructor(private _composer: ComposerService) {
        super();
        this._composer.initialised.subscribe((state) => {
            if (state) {
                this._resources = this._composer.systems;
                this.load();
            }
        });
    }

    /** List of available systems */
    public get list() {
        return this._list.getValue() || [];
    }

    /**
     * List to changes on observable
     * @param name Name of the observable
     * @param next Callback for changes to the observable
     */
    public listen(name: 'list', next: (list: EngineSystem[]) => void) {
        return this._list.subscribe(next);
    }

    /**
     * Get engine module for the given system
     * @param sys_id ID of the system to get the module from
     * @param mod_id ID of the module with the given system
     * @param index Index of the module
     */
    public get(sys_id: string, mod_id: string, index?: number): EngineModuleBinding {
        return this._composer.bindings.module(sys_id, mod_id, index);
    }

    /**
     * Load Systems
     */
    protected load() {
        if (!this.parent || !this.parent.is_initialised || !this.parent.Users || !this.parent.Users.initialised) {
            return this.timeout('load', () => this.load());
        }
        this.loadSystems();
    }

    /**
     * Load one page of systems
     * @param offset Item offset for the page to load
     */
    protected loadSystems(offset: number = 0, limit: number = 250) {
        this._resources.query({ offset, limit }).then((list) => {
            const total = this._resources.last_total;
            const systems: EngineSystem[] = [...this.list, ...list];
            this._list.next(systems.sort((a, b) => (a.name || '').localeCompare(b.name)));
            if (systems.length < total) {
                this.loadSystems(systems.length);
            }
        });
    }
}
