import { Injectable } from '@angular/core';
import { ComposerService } from '@acaprojects/ngx-composer';

import { BaseAPIService } from '../base.service';
import { Organisation } from './organisation.class';
import { Building } from './building.class';
import { HashMap } from '../../../shared/utilities/types.utilities';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends BaseAPIService {
    /** Organisation data for the application */
    private _organisation: Organisation;
    /** Actively displayed building */
    private _active_building: string;
    /** Whether an error was shown when loading organisation data */
    private shown_error: boolean;

    constructor(protected _composer: ComposerService) {
        super(_composer);
        this._name = 'Organisation';
        this._api_route = 'zones';
        this.set('buildings', []);
    }

    /**
     * Add is not available on organisation service
     */
    public async add(form_data: HashMap, query_params?: HashMap): Promise<Organisation> {
        throw new Error('Add is not available on the organisation service');
    }

    /**
     * Update is not available on organisation service
     */
    public async update(id: string, form_data: HashMap, query_params?: HashMap): Promise<Organisation> {
        throw new Error('Update is not available on the organisation service');
    }

    /**
     * Delete is not available on organisation service
     */
    public async delete(id: string): Promise<void> {
        throw new Error('Delete is not available on the organisation service');
    }

    /**
     * Get list of levels for the given building ID
     * @param bld_id Building ID
     */
    public levels(bld_id: string): any[] {
        return (this.buildings.find(i => i.id === bld_id) || {} as Building).levels;
    }

    /**
     * Get a setting from the organisation or active building
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string) {
        return this.building.setting(key) || this._organisation.setting(key);
    }

    /** Active building */
    public get building(): Building {
        return (this.buildings.find(i => i.id === this._active_building));
    }

    public set building(bld: Building) {
        if (bld instanceof Building) {
            this._active_building = bld.id;
        } else {
            this._active_building = bld;
        }
    }

    /** List of buildings for the organisation */
    public get buildings(): Building[] {
        return this.get<Building[]>('buildings') || [];
    }

    /**
     * Get list of available equipment
     * @param id ID of the building to get the list from. i.e. Defaults to the active building
     */
    public getExtras(id: string, bld_id?: string) {
        const bld = (bld_id ? this.buildings.find(i => i.id === bld_id) : null) || this.building;
        if (bld && id) {
            return bld.extras.filter(i => i.id.indexOf(id) >= 0) || [];
        }
        return [];
    }

    /**
     * Get the first level matching the list of given IDs
     * @param ids List of ID to search with
     */
    public levelWithID(ids: string | string[]) {
        const list = ids instanceof Array ? ids : [ids];
        const bld_list = this.buildings;
        for (const id of list) {
            for (const bld of bld_list) {
                for (const lvl of bld.levels) {
                    if (lvl.id === id) {
                        return lvl;
                    }
                }
            }
        }
        return null;
    }

    /**
     * Initialise service data
     */
    protected load(): Promise<void> {
        if (localStorage) {
            this._active_building = localStorage.getItem(`${this.parent.name}.building`);
        }
        return new Promise((resolve) => {
            this.loadOrganisation().then(() => {
                this.loadBuildings().then(() => {
                    this.loadLevels().then(() => resolve());
                });
            });
        });
    }

    /**
     * Load organisation data for application
     */
    public loadOrganisation(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.query({ tags: 'org', engine: true }).then((org_data) => {
                this._organisation = new Organisation(this as any, org_data[0]);
                this.set('organisation', this._organisation);
                this.shown_error = false;
                resolve();
            }, _ => {
                if (!this.shown_error) {
                    this.shown_error = true;
                    this.parent.notifyError('Error loading organisation data. Retrying...');
                }
                this.timeout('load_org', () => this.loadOrganisation().then(() => resolve()), 1000);
            });
        });
    }

    /**
     * Load buildings data for the organisation
     */
    public loadBuildings(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.query({ tags: 'building', engine: true }).then((bld_data) => {
                const buildings = (bld_data as HashMap[]).map(i => new Building(this as any, i));
                this.set('buildings', buildings || []);
                if (!this._active_building && buildings && buildings.length > 0) {
                    this._active_building = buildings[0].id;
                }
                resolve();
            }, _ => {
                this.parent.notifyError('Error loading building data. Retrying...');
                this.timeout('load_bld', () => this.loadBuildings().then(() => resolve()), 1000);
            });
        });
    }

    /**
     * Load levels data for the buildings
     */
    public loadLevels(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.query({ tags: 'level', engine: true }).then((lvl_data) => {
                resolve();
            }, _ => {
                this.parent.notifyError('Error loading level data. Retrying...');
                this.timeout('load_lvl', () => this.loadLevels().then(() => resolve()), 1000);
            });
        });
    }
}
